import React from 'react';
import {Row, Col, Button, Form, Tabs, Tab, Table} from "react-bootstrap";
import {Formik} from "formik";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomerForm = (props) => {
  return (
    <Formik
      initialValues={props.data}
      enableReinitialize={true}
      validationSchema={false}
      onSubmit={(values, {setErrors}) => {
        props.handleSubmit(values);
      }}
    >
      {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue
        }) => {

        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Naam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'name'}
                      readOnly={false}
                      placeholder='Naam'
                      type={'text'}
                      name={'name'}
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Zoeknaam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'searchName'}
                      readOnly={false}
                      placeholder='Zoeknaam'
                      type={'text'}
                      name={'searchName'}
                      value={values.searchName}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Postcode</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'postalCode'}
                      readOnly={false}
                      placeholder='Postcode'
                      type={'text'}
                      name={'postalCode'}
                      value={values.postalCode}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Adres</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'addressLine1'}
                      readOnly={false}
                      placeholder='Adres'
                      type={'text'}
                      name={'addressLine1'}
                      value={values.addressLine1}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Woonplaats</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'city'}
                      readOnly={false}
                      placeholder='Woonplaats'
                      type={'text'}
                      name={'city'}
                      value={values.city}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>KVK Nummer</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'kvkNumber'}
                      readOnly={false}
                      placeholder='KVK Nummer'
                      type={'text'}
                      name={'kvkNumber'}
                      value={values.kvkNumber}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Email</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'email'}
                      readOnly={false}
                      placeholder='Email'
                      type={'email'}
                      name={'email'}
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Weegbon email</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'weighingSlipEmail'}
                      readOnly={false}
                      placeholder='Weegbon email'
                      type={'weighingSlipEmail'}
                      name={'weighingSlipEmail'}
                      value={values.weighingSlipEmail}
                      onChange={handleChange}
                    />
                    <Form.Text className="text-muted">
                      Wanneer dit veld niet wordt ingevuld zal het normale e-mail adres van de klant gebruikt worden
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Telefoonnummer</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'phoneNumber'}
                      readOnly={false}
                      placeholder='Telefoonnummer'
                      type={'phoneNumber'}
                      name={'phoneNumber'}
                      value={values.phoneNumber}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="primary"
              type="submit"
              className="float-right"
            >
              Opslaan
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CustomerForm;
