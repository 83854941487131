import React from 'react';
import {Row, Col, Button, Form, Tabs, Tab, Table} from "react-bootstrap";
import {Formik} from "formik";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UserForm = (props) => {
  return (
    <Formik
      initialValues={props.data}
      enableReinitialize={true}
      validationSchema={false}
      onSubmit={(values, {setErrors}) => {
        props.handleSubmit(values);
      }}
    >
      {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue
        }) => {

        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Voornaam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'firstName'}
                      readOnly={false}
                      placeholder='Voornaam'
                      type={'text'}
                      name={'firstName'}
                      value={values.firstName}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Achternaam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'lastName'}
                      readOnly={false}
                      placeholder='Achternaam'
                      type={'text'}
                      name={'lastName'}
                      value={values.lastName}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Email</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'email'}
                      required={true}
                      placeholder='Email'
                      type={'email'}
                      name={'email'}
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Wachtwoord</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'password'}
                      required={!values.id}
                      placeholder='Wachtwoord'
                      type={'password'}
                      name={'password'}
                      value={values.password}
                      onChange={handleChange}
                    />
                    {values.id &&
                    <Form.Text id="passwordHelpBlock" muted>
                      Vul alleen een wachtwoord in als je deze wilt wijzigen. Laat deze anders leeg.
                    </Form.Text>
                    }
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="primary"
              type="submit"
              className="float-right"
            >
              Opslaan
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserForm;
