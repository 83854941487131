import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";
import paginationHelper from "../includes/paginationHelper";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getList = (query = '', pagination = true) => {
  if (pagination) {
    if (query) { query = '?' + query; }
    query += query === '' ? '?' + paginationHelper.getPaginationPropsFromStore('customer') : '&' + paginationHelper.getPaginationPropsFromStore('customer');
  }
  return async dispatch => {
    try {
      let url = apiRoutes.customerRelated.list;
      url = url + ((query !== '') ? query : '');
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch({
        type: actionTypes.customerRelated.GET_LIST,
        response
      });
    }
    catch (error)
    {
      toastr.error('Error loading customers');
    }
  }
};

const getAll = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.customerRelated.all);

      dispatch({
        type: actionTypes.customerRelated.GET_ALL_CUSTOMERS,
        response
      });
    }
    catch (error)
    {
      toastr.error('Error loading customers');
    }
  }
};

const postCustomer = (data) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.customerRelated.post, data);

      dispatch({
        type: actionTypes.customerRelated.POST_CUSTOMER_SUCCESS,
        response
      });

      toastr.success("Klant succesvol toegevoegd")
    }
    catch (error)
    {
      toastr.error('Error saving customer');
    }
  }
};

const getCustomer = (customerId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.customerRelated.patch.replace('{customerId}', customerId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch({
        type: actionTypes.customerRelated.GET_CUSTOMER_SUCCESS,
        response
      });
    }
    catch (error)
    {
      toastr.error('Could not recieve customer');
    }
  }
};

const patchCustomer = (customerId, data) => {
  return async dispatch => {
    try {
      const url = apiRoutes.customerRelated.patch.replace('{customerId}', customerId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch({
        type: actionTypes.customerRelated.PATCH_CUSTOMER_SUCCESS,
        response
      });

      toastr.success("Klant succesvol bijgewerkt")
    }
    catch (error)
    {
      toastr.error('Error saving customer');
    }
  }
};

const deleteCustomer = (customerId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.customerRelated.patch.replace('{customerId}', customerId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch({
        type: actionTypes.customerRelated.DELETE_CUSTOMER_SUCCESS,
        response
      });

      toastr.success("Klant verwijderd")
    }
    catch (error)
    {
      toastr.error('Error deleting customer');
    }
  }
};

const resetCustomer = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.customerRelated.RESET_CUSTOMER,
    });
  }
};

const setPaginationProps = (page, sizePerPage) => {
  page = page === 0 || page === '0' ? 1 : page;
  return async dispatch => {
    try {
      dispatch({
        type: actionTypes.customerRelated.SET_PAGINATION_PROPS,
        payload: {page, sizePerPage}
      });

      return true;
    } catch (error) {
      toastr.error(error.message);
    }
  }
};

const reset = () => {
  return async dispatch => {
    try {
      dispatch({
        type: actionTypes.customerRelated.RESET_CUSTOMERS
      })

    } catch (error) {
      toastr.error(error.message);
    }
  }
};

export default {
  getList,
  resetCustomer,
  postCustomer,
  patchCustomer,
  deleteCustomer,
  getCustomer,
  setPaginationProps,
  reset,
  getAll
}
