const apiRoutes = {
    rootUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:8084/api/',
    authRelated: {
        loginCheck: 'login_check',
        info: 'user/info'
    },
    resourceRelated: {
        list: 'resource/list',
        post: 'resource',
        patch: 'resource/{resourceId}',
        delete: 'resource/{resourceId}',
        postMaintenance: 'resource/maintenance',
        patchMaintenance: 'resource/maintenance/{maintenanceId}',
        postFile: 'resource/{resourceId}/file',
        getFile: 'resource/{resourceId}/file/{file}',
        deleteFile: 'resource/file/{file}',
        listCategories: 'resource/category/list',
        postCategory: 'resource/category',
        patchCategory: 'resource/category/{categoryId}',
        order: 'resource/order'
    },
    itemRelated: {
        list: 'planning/item/list?startDate={startDate}&endDate={endDate}',
        post: 'planning/item',
        patch: 'planning/item/{itemId}',
        delete: 'planning/item/{itemId}',
        excel: 'planning/excel?startDate={startDate}&endDate={endDate}',
        weighingSlip: 'planning/weighing_slip/{itemId}/{type}',
        email: 'planning/item/{itemId}/email',
        getLicensePlates: 'planning/license_plates'
    },
    customerRelated: {
        list: 'customer/list',
        post: 'customer',
        patch: 'customer/{customerId}',
        all: 'customer/all'
    },
    userRelated: {
        list: 'user/list',
        post: 'user',
        patch: 'user/{userId}',
        meta: 'user/meta'
    },
    productRelated: {
        list: 'product/list',
        post: 'product',
        patch: 'product/{productId}',
        getPrice: 'product/price/{customerId}/{productId}'
    },
    noticeRelated: {
        patch: 'notices',
        getNotice:'notices',
    },
    licensePlateRelated: {
        list: 'license-plate/list',
        post: 'license-plate',
        patch: 'license-plate/{licensePlateId}',
        delete: 'license-plate/{licensePlateId}',
    },
};

export {apiRoutes};
