import store from '../store';

export default class paginationHelper
{
    /**
     * moduleType has to match the path i the store to the values you want to obtain.
     *
     * @param moduleType
     * @returns {string}
     */
    static getPaginationPropsFromStore(moduleType)
    {
        const state = store.getState();

        const path = moduleType.split('.');
        if (path.length === 2) {
            return 'page=' + state[path[0]][path[1]].page + '&sizePerPage=' + state[path[0]][path[1]].pageSize;
        } else if (path.length === 3) {
            return 'page=' + state[path[0]][path[1]][path[2]].page + '&sizePerPage=' + state[path[0]][path[1]][path[2]].pageSize;
        } else {
            return 'page=' + state[moduleType].page + '&sizePerPage=' + state[moduleType].pageSize;
        }
    }

    static changePageNumberOrSize(page, pageSize, setLoading, arg1, arg2, resetList, setPaginationProps, getList)
    {
        setLoading(true);
        //resetList();
        setPaginationProps(page, pageSize).then(res => {
            if (arg1 === '' && arg2 === '') {
                getList().then(res => {
                    setLoading(false);
                });
            } else if (arg1 !== '' && arg2 === '') {
                getList(arg1).then(res => {
                    setLoading(false);
                });
            } else {
                getList(arg1, arg2).then(res => {
                    setLoading(false);
                });
            }
        });
    }
}
