import {toastr} from "react-redux-toastr";
import actionTypes from "../constants/actionTypes";
import ApiService from "../services/apiService";
import requestTypes from "../constants/requestType";
import {apiRoutes} from '../constants/apiRoutes';
import AuthenticationService from "../services/authenticationService";

const loginCheck = (data) => {
  return async dispatch => {
    try {
      dispatch({
        type: actionTypes.authRelated.LOGIN
      });

      const response = await ApiService.performRequest(requestTypes.POST, apiRoutes.authRelated.loginCheck, data);

      AuthenticationService.setToken(response.token);

      dispatch({
        type: actionTypes.authRelated.LOGIN_SUCCESS,
        token: response.token
      });

    } catch (error) {
      dispatch({
        type: actionTypes.authRelated.LOGIN_FAIL,
        payload: {}
      });
    }
  }
};

const info = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(requestTypes.GET, apiRoutes.authRelated.info);
      dispatch({
        type: actionTypes.authRelated.GET_INFO_SUCCESS,
        response
      });
    } catch (error) {

    }
  }
};

const logout = () => {
  return async dispatch => {
    try {
      dispatch({
        type: actionTypes.authRelated.LOGOUT,
        payload: {}
      })
    } catch (error) {
      toastr.error(error.message)
    }
  }
};

export default {
  logout,
  loginCheck,
  info
}
