import React, {useState,Fragment} from "react";
import {Form, Button, Container, Row, Col} from 'react-bootstrap';
import {bindActionCreators} from "redux";
import authenticationActions from "../actions/authenticationActions";
import {connect} from "react-redux";
import BackgroundImage from '../assets/img/achtergrond.jpg';
import Logo from '../assets/img/logo-1.png';

const Login = (props) => {

  const [loginData, setLoginData] = useState({
    '_username': '',
    '_password': ''
  });

  const handleChange = (e) => {
    const {name,value} = e.target;
    let newLoginData = {...loginData};

    newLoginData[name] = value;

    setLoginData(newLoginData);
  };

  const login = (e) => {
    e.preventDefault();
    props.actions.loginCheck(loginData);
  };

  return (
    <div className='justify-content-center' style={{minHeight: '100vh',backgroundSize: 'cover', backgroundImage: `url(${BackgroundImage}`}}>
      <Col className='loginBox' xl={3} lg={5} md={8} sm={12}>
        <Form onSubmit={login}>

          <div style={{textAlign: 'center'}}>
            <img src={Logo} style={{margin: '0 auto'}} />

            <h4>Inloggen</h4>
          </div>

          <Form.Group controlId="formBasicEmail">
            <Form.Control
              value={loginData._username}
              name='_username'
              type="text"
              placeholder="Email"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Control
              value={loginData._password}
              name='_password'
              type="password"
              placeholder="Wachtwoord"
              onChange={handleChange}
            />
          </Form.Group>

          {props.authentication.loginMessage &&
            <Fragment>
              <div style={{color: '#ff0000'}}>{props.authentication.loginMessage}</div>
              <br />
            </Fragment>
          }


          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
      </Col>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication
  }
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(authenticationActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
