import React, {Fragment, useEffect, useState} from 'react';
import {Container, Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import CustomerForm from "./form";
import {Pencil, Trash} from "react-bootstrap-icons";
import userActions from "../../../actions/userActions";
import moment from 'moment';

const Users = (props) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const {users} = props.user;

  useEffect(() => {
    props.userActions.getList();
  }, []);

  const columns = [
    {
      dataField: 'id',
      text: 'id'
    },
    {
      dataField: 'email',
      text: 'Email',
      filter: textFilter()
    },
    {
      dataField: 'firstName',
      text: 'Voornaam',
      filter: textFilter()
    },
    {
      dataField: 'lastName',
      text: 'Achternaam',
      filter: textFilter()
    },
    {
      dataField: 'lastLogin',
      text: 'Laatste inlog',
      formatter: (cell,row) => {
        return cell ? `${moment(cell).format("DD-MM-YYYY HH:mm") }` : ``;
      }
    },
    {
      dataField: 'id',
      formatter: (cell, row) => {
        return <Button size="sm" variant="secondary" onClick={() => { setSelectedUser(row); setShowModal(true); }}><Pencil /></Button>
      }
    },
    {
      dataField: 'id',
      formatter: (cell, row) => {
        return <Button size="sm" variant="danger" onClick={() => {
          if (window.confirm('Weet je het zeker?')) {
            props.userActions.deleteUser(row.id).then(() => props.userActions.getList());
          }
        }}><Trash/></Button>
      }
    }
  ];

  const handleSubmit = (values) => {
    let newData = {...values};
    if(values.id) {
      delete newData.id;
      props.userActions.patchUser(values.id, newData).then(() => {
        setShowModal(false);
        props.userActions.getList();
      });
    } else {
      props.userActions.postUser(newData).then(() => {
        setShowModal(false);
        props.userActions.getList();
      });
    }
  };

  return (
    <Container fluid>
      <h2>Gebruikers</h2>

      <Button
        variant="success"
        onClick={() => {
          setSelectedUser(props.user.emptyUser);
          setShowModal(true);
        }}
      >
        Nieuw
      </Button> <br /><br />

      <BootstrapTable
        keyField='id'
        data={users}
        columns={columns}
        filter={filterFactory()}
        bootstrap4={true}
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Gebruiker formulier
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerForm
            handleSubmit={handleSubmit}
            data={selectedUser}
          />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
