import React, {Fragment, useEffect, useState} from 'react';
import {Container, Button, Modal, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import noticeActions from "../../../actions/noticeActions";


const Notices = (props) => {

  const [notice, setNotice] = useState('');

  useEffect(() => {
    props.noticeActions.getNotice().then(response =>
    {
      if(response.message !== null){
        setNotice(response.message);
      }
    })
  }, []);

  const handleSubmit = (data) => {
    data.preventDefault();
    let newData = {
      id: 1,
      message : data.target.notice.value
    };

    props.noticeActions.patchNotice(newData);
  }

  return (
    <Container fluid>
      <h2>Mededelingen</h2>
      <Fragment>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              name={'notice'}
              type="text"
              value={notice}
              onChange={(e) => setNotice(e.target.value)}
              as="textarea"
              rows={3}/>
          </Form.Group>
          <Button  type="submit">Aanpassen</Button>
        </Form>
      </Fragment>
    </Container>
  )
}
const mapStateToProps = (state) => {
  return {
    notice: state.notice
  }
};

const mapDispatchToProps = dispatch => {
  return {
    noticeActions: bindActionCreators(noticeActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notices);
