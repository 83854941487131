import React, {Fragment, useEffect, useState} from 'react';
import {Container, Button, Tabs, Tab, Table, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import customerActions from "../../../actions/customerActions";
import { useParams, useHistory } from "react-router-dom";
import CustomerForm from "./form";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import productActions from "../../../actions/productActions";
import {Typeahead} from "react-bootstrap-typeahead";
import PriceForm from "./priceForm";

const Customer = (props) => {

  const { id } = useParams();
  const history = useHistory();

  const {customer} = props.customer;

  useEffect(() => {
    props.customerActions.resetCustomer().then(() => props.customerActions.getCustomer(id));
    props.productActions.getList();
  }, []);

  const handleSubmit = (values) => {
    let newData = {...values};
    delete newData.id;
    props.customerActions.patchCustomer(values.id, newData).then(() => {
      props.customerActions.getCustomer(id);
    });
  };

  const handlePriceSubmit = (values) => {
    const submitData = {prices: [...values]};
    props.customerActions.patchCustomer(customer.id, submitData);
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Artikelcode'
    },
    {
      dataField: 'description',
      text: 'Omschrijving'
    },
    {
      dataField: 'price_per',
      text: 'Prijs per'
    },
    {
      dataField: 'unit',
      text: 'Eenheid'
    },
    {
      dataField: 'verk_incl',
      text: 'Verkoop prijs'
    },
    {
      dataField: 'discount_incl',
      text: 'Korting'
    },
  ];

  return (
    <Container fluid>
      <h2>Bewerk {customer.name}</h2>

      <Button variant="link" onClick={() => history.push('/admin/customers')}>Terug</Button>

      <Tabs defaultActiveKey="form" id="uncontrolled-tab-example">
        <Tab eventKey="form" title="Klant gegevens">
          <h3>Klant gegevens</h3>
          <CustomerForm
            handleSubmit={handleSubmit}
            data={customer}
          />
        </Tab>
        <Tab eventKey="prices" title="Prijsafspraken">
          <PriceForm
            products={props.products}
            prices={customer.prices ? customer.prices : []}
            handleSubmit={handlePriceSubmit}
          />
        </Tab>
        <Tab eventKey="history" title="Orderhistorie">
          <Table striped bordered>
            <thead>
            <tr>
              <th>#</th>
              <th>Planning</th>
              <th>Startdatum</th>
              <th>Einddatum</th>
            </tr>
            </thead>
            <tbody>
            {customer.planningItems && customer.planningItems.filter(item => item.invoiced === true).map(item =>
              <Fragment>
                <tr>
                  <td>#{item.id}</td>
                  <td>{item.title}</td>
                  <td>{moment(item.start).subtract(2, 'hours').format('YYYY-MM-DD HH:mm')}</td>
                  <td>{moment(item.end).subtract(2, 'hours').format('YYYY-MM-DD HH:mm')}</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <strong>Projecten</strong> <br />

                    <Table style={{fontSize: 12}}>
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Project</th>
                        <th>Product</th>
                        <th>Prijs</th>
                      </tr>
                      </thead>
                      <tbody>
                      {item.projects.map(project =>
                        <tr>
                          <td>{project.id}</td>
                          <td>{project.description}</td>
                          <td>{project.productName}</td>
                          <td>{project.productPrice}</td>
                        </tr>
                      )}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </Fragment>
            )}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    products: state.product.products
  }
};

const mapDispatchToProps = dispatch => {
  return {
    customerActions: bindActionCreators(customerActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
