import React from 'react';
import {Row, Col, Button, Form, Tabs, Tab, Table} from "react-bootstrap";
import {Formik} from "formik";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ProductForm = (props) => {
  return (
    <Formik
      initialValues={props.data}
      enableReinitialize={true}
      validationSchema={false}
      onSubmit={(values, {setErrors}) => {
        props.handleSubmit(values);
      }}
    >
      {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue
        }) => {

        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Artikelcode</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'code'}
                      readOnly={false}
                      placeholder='Artikelcode'
                      type={'text'}
                      name={'code'}
                      value={values.code}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Zoeknaam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'searchName'}
                      readOnly={false}
                      placeholder='Zoeknaam'
                      type={'text'}
                      name={'searchName'}
                      value={values.searchName}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Omschrijving</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'description'}
                      readOnly={false}
                      placeholder='Omschrijving'
                      type={'text'}
                      name={'description'}
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Grootboek</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'ledger'}
                      readOnly={false}
                      placeholder='Grootboek'
                      type={'text'}
                      name={'ledger'}
                      value={values.ledger}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Prijs per</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'pricePer'}
                      readOnly={false}
                      placeholder='Prijs per'
                      type={'number'}
                      name={'pricePer'}
                      value={values.pricePer}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Eenheid</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      as="select"
                      name='unit'
                      value={values.unit}
                      onChange={handleChange}
                    >
                      <option value={null}>Selecteer</option>
                      <option value={'m3'}>m3</option>
                      <option value={'kg'}>kg</option>
                      <option value={'st'}>st</option>
                      <option value={'st.'}>st.</option>
                      <option value={'ton'}>ton</option>
                      <option value={'uur'}>uur</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Prijs</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'price'}
                      readOnly={false}
                      placeholder='Prijs'
                      type={'number'}
                      name={'price'}
                      value={values.price}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>BTW Percentage</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      as="select"
                      name='btwPercentage'
                      value={values.btwPercentage}
                      onChange={handleChange}
                    >
                      <option value={0}>0%</option>
                      <option value={9}>9%</option>
                      <option value={21}>21%</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="primary"
              type="submit"
              className="float-right"
            >
              Opslaan
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ProductForm;
