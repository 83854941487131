import React from 'react';
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {Formik} from "formik";
import { SketchPicker } from 'react-color';
import FileBase64 from "react-file-base64";
import {apiRoutes} from "../../../constants/apiRoutes";

const ResourceForm = (props) => {

  const { showModal, setShowModal, selectedResource, categories } = props;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Middel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={selectedResource}
          enableReinitialize={true}
          validationSchema={false}
          onSubmit={(values, {setErrors}) => {
            props.handleSubmit(values);
          }}
        >
          {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue
            }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Naam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'name'}
                      readOnly={false}
                      placeholder='Naam'
                      type={'text'}
                      name={'name'}
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Categorie</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      as="select"
                      name='category'
                      value={values.category ? values.category.id : null}
                      onChange={(e) => {
                        setFieldValue('category', {id: parseInt(e.target.value)})
                      }}
                    >
                      <option value={null}>Selecteer een categorie</option>
                      {categories.map(category =>
                        <option key={category.id} value={category.id}>{category.name}</option>
                      )}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Kleur</Form.Label>
                  <Col lg={9}>
                    <SketchPicker
                      color={{
                        hex: values.color
                      }}
                      onChangeComplete={(color) => setFieldValue('color', color.hex)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Afbeelding</Form.Label>
                  <Col lg={9}>
                    <FileBase64
                      multiple={false}
                      onDone={(file) => setFieldValue('imageFile', file.base64)}
                    />
                  </Col>
                </Form.Group>

                {values.hasImage &&
                  <Form.Group as={Row}>
                    <Form.Label column={true} lg={3}>Huidige afbeelding</Form.Label>
                    <Col lg={9}>
                      <img
                        width={150}
                        height={150}
                        alt={"Image"}
                        src={`${apiRoutes.rootUrl}resource/${values.id}/image`}
                      />
                    </Col>
                  </Form.Group>
                }

                <Button type="submit" variant="primary">Opslaan</Button>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ResourceForm;