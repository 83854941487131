import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'
import moment from "moment";

const getItems = (startDate, endDate) => {
  return async dispatch => {
    try {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');

      const url = apiRoutes.itemRelated.list.replace('{startDate}', startDate).replace('{endDate}', endDate);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch({
        type: actionTypes.itemRelated.GET_ITEMS_SUCCESS,
        response
      })
    }
    catch (error)
    {
      toastr.error('Kon de planning items niet ophalen');

      dispatch({
        type: actionTypes.itemRelated.GET_ITEMS_FAIL
      });
    }
  }
};

const postItem = (item) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.itemRelated.post, item);

      dispatch({
        type: actionTypes.itemRelated.POST_ITEM_SUCCESS,
        response
      });

      toastr.success('Planning item opgeslagen!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon het plannings item niet opslaan');

      dispatch({
        type: actionTypes.itemRelated.POST_ITEM_FAIL
      });

      return {success: false};
    }
  }
}

const patchItem = (itemId, item) => {
  return async dispatch => {
    try {
      const url = apiRoutes.itemRelated.patch.replace('{itemId}', itemId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, item);

      dispatch({
        type: actionTypes.itemRelated.PATCH_ITEM_SUCCESS,
        response
      });

      toastr.success('Planning item opgeslagen!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon het plannings item niet opslaan');

      dispatch({
        type: actionTypes.itemRelated.PATCH_ITEM_FAIL
      });

      return {success: false};
    }
  }
}

const emailItem = (itemId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.itemRelated.email.replace('{itemId}', itemId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      toastr.success('Email verstuurd!');
    }
    catch (error) {
      toastr.error('Kon email niet versturen. Probeer het later opnieuw');
    }
  }
};

const deleteItem = (itemId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.itemRelated.delete.replace('{itemId}', itemId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch({
        type: actionTypes.itemRelated.DELETE_ITEM_SUCCESS,
        itemId
      });

      toastr.success('Planning item verwijderd!');
    }
    catch (error) {
      toastr.error('Kan het item niet verwijderen, probeer het later opnieuw');
    }
  }
};

const downloadExcel = (startDate, endDate, invoicing, resources) => {
  return async dispatch => {
    try {
      startDate = moment(startDate).format('YYYY-MM-DD');
      endDate = moment(endDate).format('YYYY-MM-DD');
      const url = apiRoutes.itemRelated.excel.replace('{startDate}', startDate).replace('{endDate}', endDate);

      const data = {
        invoicing,
        resources
      };

      return await ApiService.performRequest(RequestType.POST_FILE, url, data);
    }
    catch (error)
    {
      console.log(error);
      toastr.error('Kan de excel niet downloaden. Probeer het later opnieuw');

      return false;
    }
  }
};

const downloadWeighingSlip = (itemId, type) => {
  return async dispatch => {
    try {
      const url = apiRoutes.itemRelated.weighingSlip.replace('{itemId}', itemId).replace('{type}', type);

      return await ApiService.performRequest(RequestType.GET_FILE, url);
    }
    catch (error)
    {
      console.log(error);
      toastr.error('Kan de excel niet downloaden. Probeer het later opnieuw');

      return false;
    }
  }
};

const getLicensePlates = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.itemRelated.getLicensePlates);

      dispatch({
        type: actionTypes.itemRelated.GET_LICENSE_PLATES_SUCCESS,
        response
      });
    }
    catch (error)
    {
      //
    }
  }
};

export default {
  getItems,
  postItem,
  patchItem,
  deleteItem,
  downloadExcel,
  downloadWeighingSlip,
  emailItem,
  getLicensePlates
}
