import React, {Fragment} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from './store';
import {connect, Provider} from 'react-redux';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from "react-router-dom";
import ReduxToastr from 'react-redux-toastr';

import './assets/global.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Planning from "./containers/Planning";
import Admin from './containers/Admin';
import AuthenticationService from "./services/authenticationService";
import {bindActionCreators} from "redux";
import authenticationActions from "./actions/authenticationActions";
import Login from "./containers/Login";

const AppRouter = (props) => {
  return (
    <Router>

      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates={true}
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={false}
      />

      {AuthenticationService.isLoggedIn(props.authentication.isLoggedIn) ?
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/" component={Planning} />
        </Switch>
        :
        <Switch>
          <Route path="/login" component={Login} />
          <Redirect to={'/login'} />
        </Switch>
      }
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication
  }
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(authenticationActions, dispatch)
  }
};

const AppRouterContainer = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

const App = () => (
  <Provider store={store}>
    <AppRouterContainer />
  </Provider>
)

export default App;