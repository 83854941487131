import {actionTypes} from '../constants/actionTypes';

const emptyProduct = {
  code: '',
  searchName: '',
  description: '',
  ledger: '',
  pricePer: 1,
  unit: '',
  price: 0,
  btwPercentage: 19
}

const initialState = {
  products: [],
  emptyProduct: {...emptyProduct},
  product: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.productRelated.GET_PRODUCT_LIST:
      return {
        ...state,
        products: action.response
      };
    case actionTypes.productRelated.RESET_PRODUCT:
      return {
        ...state,
        emptyProduct: {...emptyProduct},
        product: {}
      }
    case actionTypes.productRelated.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.response
      }
    default:
      return state;
  }
};
