import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getList = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.productRelated.list);

      dispatch({
        type: actionTypes.productRelated.GET_PRODUCT_LIST,
        response
      });
    }
    catch (error)
    {
      toastr.error('Error loading products');
    }
  }
};

const getPrice = (customerId, productId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.productRelated.getPrice
        .replace('{customerId}', customerId)
        .replace('{productId}', productId);

      return await ApiService.performRequest(RequestType.GET, url);
    }
    catch (error)
    {
      //toastr.error('Error retrieving product price');
    }
  }
};

const postProduct = (data) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.productRelated.post, data);

      dispatch({
        type: actionTypes.productRelated.POST_PRODUCT_SUCCESS,
        response
      });

      toastr.success("Product succesvol toegevoegd")
    }
    catch (error)
    {
      toastr.error('Error saving product');
    }
  }
};

const getProduct = (productId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.productRelated.patch.replace('{productId}', productId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch({
        type: actionTypes.productRelated.GET_PRODUCT_SUCCESS,
        response
      });
    }
    catch (error)
    {
      toastr.error('Could not recieve product');
    }
  }
};

const patchProduct = (productId, data) => {
  return async dispatch => {
    try {
      const url = apiRoutes.productRelated.patch.replace('{productId}', productId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch({
        type: actionTypes.productRelated.PATCH_PRODUCT_SUCCESS,
        response
      });

      toastr.success("Product succesvol bijgewerkt")
    }
    catch (error)
    {
      toastr.error('Error saving product');
    }
  }
};

const deleteProduct = (productId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.productRelated.patch.replace('{productId}', productId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch({
        type: actionTypes.productRelated.DELETE_PRODUCT_SUCCESS,
        response
      });

      toastr.success("Product verwijderd")
    }
    catch (error)
    {
      toastr.error('Error deleting product');
    }
  }
};

const resetProduct = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.productRelated.RESET_PRODUCT,
    });
  }
};

export default {
  getList,
  postProduct,
  getProduct,
  patchProduct,
  deleteProduct,
  resetProduct,
  getPrice
}
