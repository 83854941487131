import {actionTypes} from '../constants/actionTypes';

const emptyUser = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
}

const initialState = {
  users: [],
  emptyUser: {...emptyUser},
  meta: {
    viewType: null,
    resourceGroups: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.userRelated.GET_USER_LIST:
      return {
        ...state,
        users: action.response
      };
    case actionTypes.userRelated.RESET_USER:
      return {
        ...state,
        emptyUser: {...emptyUser}
      }
    case actionTypes.userRelated.GET_USER_META_SUCCESS:
      const responseMeta = action.response.meta ? action.response.meta : {};
      return {
        ...state,
        meta: {...state.meta, ...responseMeta}
      }
    case actionTypes.userRelated.SET_USER_META:
      return {
        ...state,
        meta: action.meta
      }
    default:
      return state;
  }
};
