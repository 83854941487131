import React from 'react';
import {Container, Navbar, Nav, NavDropdown} from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";

import Resources from "./Resources";
import Customers from './Customers';
import Customer from './Customers/customer';
import Users from './Users';
import Products from './Products';
import Home from "./home";
import Notices from "./Notices";
import LicensePlates from "./LicensePlates";


const Admin = (props) => {
  const {match} = props;

  return (
    <Container fluid>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand as={Link} to={'/'}>Admin</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/admin">Home</Nav.Link>
            <Nav.Link as={Link} to="/admin/customers">Klanten</Nav.Link>
            <Nav.Link as={Link} to="/admin/resources">Middelen</Nav.Link>
            <Nav.Link as={Link} to="/admin/products">Artikelen</Nav.Link>
            <Nav.Link as={Link} to="/admin/users">Gebruikers</Nav.Link>
            <Nav.Link as={Link} to="/admin/notices">Mededelingen</Nav.Link>
            <Nav.Link as={Link} to="/admin/license-plates">Kentekens</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Route path={`${match.path}`} component={Home} default={true} exact={true} />
      <Route path={`${match.path}/customers`} exact={true} component={Customers} />
      <Route path={`${match.path}/customer/:id`} component={Customer} />
      <Route path={`${match.path}/resources`} exact={true} component={Resources}/>
      <Route path={`${match.path}/users`} exact={true} component={Users}/>
      <Route path={`${match.path}/products`} exact={true} component={Products}/>
      <Route path={`${match.path}/notices`} exact={true} component={Notices}/>
      <Route path={`${match.path}/license-plates`} exact={true} component={LicensePlates}/>
    </Container>
  )
}

export default Admin;
