import React, {Fragment, useState} from 'react';
import {Row, Col, Button, Form, Tabs, Tab} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {Formik} from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import BootstrapTable from 'react-bootstrap-table-next';
import FileBase64 from "react-file-base64";
import {apiRoutes} from "../../constants/apiRoutes";

const Resource = (props) => {

  const [showFileForm, setShowFileForm] = useState(false);

  const columns = [
    {
      dataField: 'date',
      text: 'Datum',
      sort: true,
      formatter: (cell, row) =>
        <Fragment>
          {row.date ? moment(row.date).format('DD-MM-YYYY') : ''}
        </Fragment>
    },
    {
      dataField: 'kilometres',
      text: 'km stand',
      sort: true
    },
    {
      dataField: 'type',
      text: 'type',
      sort: false,
      formatter: (cell, row) =>
        <Fragment>
          {row.fullService && 'Grote beurt'}
          {row.smallService && 'Kleine beurt'}
          {row.examination && 'Keuring'}
        </Fragment>
    },
    {
      dataField: 'carriedOutBy',
      text: 'uitgevoerd door',
      sort: true
    }
  ];

  const expandRow = {
    renderer: row =>
      <div>
        {row.activities &&
          <div>
            <strong>Werkzaamheden:</strong><br />
            {row.activities}

            <br /><br />
          </div>
        }
        {row.remarks &&
          <div>
            <strong>Opmerkingen</strong><br />
            {row.remarks}
          </div>
        }

        <Form.Check
          label="Onderhoud klaar?"
          type="checkbox"
          id="doneCheck"
          name="doneCheck"
          checked={row.done}
          onChange={(e) => {
            const data = {
              id: row.id,
              done: e.target.checked
            };
            props.handleMaintenanceSubmit(data);
          }}
        />
      </div>
  }

  const fileColumns = [
    {
      dataField: 'name',
      text: 'Naam',
      sort: true
    },
    {
      dataField: 'createdAt',
      text: 'Datum',
      sort: true,
      formatter: (cell, row) => {
        return moment(row.createdAt).format('DD-MM-YYYY HH:mm');
      }
    },
    {
      dataField: 'id',
      text: 'Download',
      sort: true,
      formatter: (cell, row) => {
        return (
          <Fragment>
            <Button variant="link" onClick={() => {
              props.downloadFile(props.resource.id, row.id);
            }}>Download</Button>
          </Fragment>
        )
      }
    },
    {
      dataField: 'id',
      text: 'Acties',
      formatter: (cell, row) => {
        return (
          <Button
            variant="danger"
            onClick={() => {
              if(window.confirm('Weet je zeker dat je dit bestand wilt verwijderen?')) {
                props.deleteFile(row.id);
              }
            }}
          >
            Delete
          </Button>
        )
      }
    }
  ];

  return (
    <Tabs defaultActiveKey="maintenance" id="uncontrolled-tab-example">
      <Tab eventKey="maintenance" title={`Onderhoud (${props.resource.maintenances.length})`}>
        <Row>
          <Col>
            <h4>Onderhoud - {props.resource.name}</h4>

            <Formik
              initialValues={props.resourceMaintenance}
              enableReinitialize={true}
              validationSchema={false}
              onSubmit={(values, {setErrors}) => {
                props.handleMaintenanceSubmit(values);
              }}
            >
              {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue
                }) => {
                return (
                  <Form onSubmit={handleSubmit}>

                    <Form.Group as={Row}>
                      <Form.Label column={true} lg={3}>Uren/km stand</Form.Label>
                      <Col lg={9}>
                        <Form.Control
                          id={'kilometres'}
                          readOnly={false}
                          placeholder='Uren/km stand'
                          type={'text'}
                          name={'kilometres'}
                          value={values.kilometres}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column={true} lg={3}>Datum onderhoud</Form.Label>
                      <Col lg={9}>
                        <DatePicker
                          selected={values.date}
                          onChange={(date) => setFieldValue('date', date)}
                          className={'form-control form-control'}
                          dateFormat="dd-MM-yyyy"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column={true} lg={3}>Type onderhoud</Form.Label>
                      <Col lg={9}>
                        <Form.Check
                          inline
                          label="Grote beurt"
                          type="checkbox"
                          id="fullService"
                          name="fullService"
                          checked={values.fullService}
                          onChange={handleChange}
                        />

                        <Form.Check
                          inline
                          label="Kleine beurt"
                          type="checkbox"
                          id="smallService"
                          name="smallService"
                          checked={values.smallService}
                          onChange={handleChange}
                        />

                        <Form.Check
                          inline
                          name="examination"
                          label="Keuring"
                          type="checkbox"
                          id="examination"
                          checked={values.examination}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column={true} lg={3}>Uitgevoerd door</Form.Label>
                      <Col lg={9}>
                        <Form.Control
                          id={'carriedOutBy'}
                          readOnly={false}
                          placeholder='Uitgevoerd door'
                          type={'text'}
                          name={'carriedOutBy'}
                          value={values.carriedOutBy}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column={true} lg={3}>Aantal liter motorolie bijgevuld</Form.Label>
                      <Col lg={9}>
                        <Form.Control
                          id={'motorLitres'}
                          readOnly={false}
                          placeholder='Aantal liter'
                          type={'text'}
                          name={'motorLitres'}
                          value={values.motorLitres}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column={true} lg={3}>Aantal liter hydrauliekolie bijgevuld</Form.Label>
                      <Col lg={9}>
                        <Form.Control
                          id={'hydraulicLitres'}
                          readOnly={false}
                          placeholder='Aantal liter'
                          type={'text'}
                          name={'hydraulicLitres'}
                          value={values.hydraulicLitres}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column={true} lg={3}>Onderhoud klaar?</Form.Label>
                      <Col lg={9}>
                        <Form.Check
                          inline
                          label="Onderhoud klaar?"
                          type="checkbox"
                          id="done"
                          name="done"
                          checked={values.done}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.activities">
                      <Form.Label>Werkzaamheden</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="activities"
                        value={values.activities}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.remarks">
                      <Form.Label>Opmerkingen</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                      />
                    </Form.Group>


                    <Button
                      variant="primary"
                      type="submit"
                    >
                      Toevoegen
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </Col>
          <Col>
            <h4>Overzicht onderhoud</h4>
            <BootstrapTable
              keyField='id'
              data={props.resource.maintenances}
              columns={columns}
              expandRow={expandRow}
            />
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="files" title={`Bestanden (${props.resource.files.length})`}>
        <Row>
          <Col>
            <h4>Bestanden</h4>

            {showFileForm &&
              <Formik
                initialValues={props.resourceFile}
                enableReinitialize={true}
                validationSchema={false}
                onSubmit={(values, {setErrors}) => {
                  props.handleFileSubmit(props.resource.id, values);
                  setShowFileForm(false);
                }}
              >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    setFieldValue
                  }) => {
                  return (
                    <Form onSubmit={handleSubmit}>

                      <Form.Group as={Row}>
                        <Form.Label column={true} lg={3}>Naam</Form.Label>
                        <Col lg={9}>
                          <Form.Control
                            id={'name'}
                            readOnly={false}
                            placeholder='Naam'
                            type={'text'}
                            name={'name'}
                            value={values.name}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column={true} lg={3}>Bestand</Form.Label>
                        <Col lg={9}>
                          <FileBase64
                            multiple={false}
                            onDone={(file) => setFieldValue('file', file.base64)}
                          />
                        </Col>
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                      >
                        Toevoegen
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            }

            {!showFileForm && <Button onClick={() => setShowFileForm(!showFileForm)} variant="primary">Voeg bestand toe</Button>}

            <br /><br />

            <BootstrapTable keyField='id' data={props.resource.files} columns={fileColumns}/>
          </Col>
        </Row>
      </Tab>
    </Tabs>
  )
}

export default Resource;
