import React, {useEffect, useState, Fragment, useRef} from 'react';
import {Row, Col, Container, Modal, Form, Button} from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import '../../assets/calendar-custom.css';
import {bindActionCreators} from "redux";
import resourceActions from "../../actions/resourceActions";
import {connect} from "react-redux";
import PlanningForm from "./form";
import moment from 'moment';
import itemActions from "../../actions/itemActions";
import {Pencil} from 'react-bootstrap-icons';
import Resource from "./resource";
import {useHistory} from "react-router";
import {apiRoutes} from "../../constants/apiRoutes";
import FileDownload from "js-file-download";
import customerActions from "../../actions/customerActions";
import userActions from "../../actions/userActions";
import productActions from "../../actions/productActions";
import authenticationActions from "../../actions/authenticationActions";

const Planning = (props) => {
  const history = useHistory();
  const {resources, emptyResourceMaintenance} = props.resource;
  const {emptyItem, items, emptyProject} = props.item;
  const customers = props.customers;

  const [showModal, setShowModal] = useState(false);
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({...emptyItem});
  const [selectedResource, setSelectedResource] = useState({});
  const [resourceMaintenance, setResourceMaintenance] = useState({});
  const [resourceFile, setResourceFile] = useState({name: 'Bestandsnaam', file: ''});
  const [resourcesToInvoice, setResourcesToInvoice] = useState([]);
  const [saving, setSaving] = useState(false);

  const calendarRef = useRef(null);

  useEffect(() => {
    props.userActions.getMeta().then((res) => {
      const meta = res ? res.meta : {};
      let viewType = 'resourceTimelineWeek';
      if (meta.viewType) {
        viewType = meta.viewType;
      }

      calendarRef.current.getApi().changeView(viewType);
    });
    props.resourceActions.getResources();
    props.customerActions.getAll();
    props.productActions.getList();
    props.itemActions.getLicensePlates();
  }, []);

  useEffect(() => {
    setResourcesToInvoice(resources);
  }, [resources]);

  useEffect(() => {
    updateItems();
  }, [calendarRef.current]);

  const getFormattedResources = () => {
    let newResources = resources.map(resource => {
      const categoryName = resource.category ? resource.category.name : 'Geen categorie';
      return {
        id: resource.id,
        title: resource.name,
        eventBackgroundColor: resource.color ?? '#F6A604',
        category: categoryName,
        categoryDisplayOrder: resource.categoryDisplayOrder + ' - ' + categoryName,
        displayOrder: resource.displayOrder
      };
    });

    return newResources.sort((a, b) => a.categoryDisplayOrder - b.categoryDisplayOrder);
  };

  const getFormattedItems = () => {
    return items.map(item => {
      let title = '';
      if (item.invoiced) {
        title = item.title + ' (gefactureerd)';
      } else {
        title = item.title;
      }

      return {
        ...item,
        title
      }
    });
  };

  const downloadFile = (resourceId, fileId) => {
    props.resourceActions.downloadFile(resourceId, fileId).then(response => {
      FileDownload(response.blob, response.fileName)
    })
  }

  const handleSubmit = (data, withWeighingSlip = false, weighingSlipType = 'excel', email = false) => {
    setSaving(true);
    let newData = transformPostData(data);
    const fileName = weighingSlipType === 'excel' ? 'Weegbon.xlsx' : 'Weegbon.pdf';
    if (newData.id) {
      delete newData.id;
      props.itemActions.patchItem(data.id, newData).then((response) => {
        if (response.success) {
          setShowModal(false);

          if (withWeighingSlip) {
            props.itemActions.downloadWeighingSlip(response.item.id, weighingSlipType).then(response => {
              if (response) {
                FileDownload(response, fileName);
              }
            });
          }

          if (email) {
            props.itemActions.emailItem(response.item.id);
          }
        }
        props.itemActions.getLicensePlates();
        setSaving(false);
      });
    } else {
      props.itemActions.postItem(newData).then((response) => {
        if (response.success) {
          setShowModal(false);

          if (withWeighingSlip) {
            props.itemActions.downloadWeighingSlip(response.item.id, weighingSlipType).then(response => {
              if (response) {
                FileDownload(response, fileName);
              }
            });
          }

          if (email) {
            props.itemActions.emailItem(response.item.id);
          }
        }
        props.itemActions.getLicensePlates();
        setSaving(false);
      });
    }
  };

  const handleDeleteItem = (itemId) => {
    props.itemActions.deleteItem(itemId).then(() => setShowModal(false));
  };

  const handleDuplicateItem = (data) => {
    let newData = transformPostData(data);
    delete newData.id;
    newData.projects = [];
    props.itemActions.postItem(newData).then(() => {
      setShowModal(false);
    });
  };

  const handleMaintenanceSubmit = (data) => {
    let newData = {...data};

    if (data.date) {
      newData.date = data.date ? moment(data.date).format('YYYY-MM-DD') : null;
    }

    if (data.id) {
      delete newData.id;
      props.resourceActions.patchMaintenance(data.id, newData);
    } else {
      props.resourceActions.postMaintenance(newData);
    }


    setResourceMaintenance({
      ...emptyResourceMaintenance,
      planningResource: newData.planningResource
    });
  }

  const transformPostData = (data) => {
    let newData = {...data};
    delete newData.planningResource;
    newData.planningResource = data.planningResource.id;
    newData.start = data.start ? moment(data.start).format('YYYY-MM-DD HH:mm') : null;
    newData.end = data.end ? moment(data.end).format('YYYY-MM-DD HH:mm') : null;

    return newData;
  }

  const handleResourceClick = (resourceId) => {
    const foundIndex = resources.findIndex(res => res.id === resourceId);

    if (foundIndex > -1) {
      setSelectedResource(resources[foundIndex]);
      setResourceMaintenance({
        ...emptyResourceMaintenance,
        planningResource: resources[foundIndex].id
      });
      setShowResourceModal(true);
    }
  };

  const handleFileSubmit = (resourceId, data) => {
    props.resourceActions.postFile(resourceId, data).then(res => {
      setResourceFile({name: 'Bestandsnaam', file: ''});
    });
  };

  const handleDeleteFile = (fileId) => {
    props.resourceActions.deleteFile(fileId);
  };

  const updateItems = () => {
    if (calendarRef.current) {
      const {activeStart, activeEnd} = calendarRef.current._calendarApi.view;

      props.itemActions.getItems(activeStart, activeEnd);
    }
  };

  const toggleResourceGroupVisibility = (name, visible) => {
    let currentMeta = {...props.user.meta};
    const index = currentMeta.resourceGroups.findIndex(group => group.name === name);

    if (index > -1) {
      currentMeta.resourceGroups[index]['visible'] = visible;
    } else {
      currentMeta.resourceGroups.push({
        name,
        visible
      });
    }

    props.userActions.saveMeta(currentMeta);
  };

  const changeViewType = (type) => {
    if (calendarRef.current) {
      let currentMeta = {...props.user.meta};
      if (currentMeta.viewType !== type) {
        currentMeta.viewType = type;

        props.userActions.saveMeta(currentMeta);
      }

    }
  }

  const deselectAllResources = () => {
    setResourcesToInvoice([]);
  };

  const selectAllResources = () => {
    setResourcesToInvoice(resources);
  };

  const getFormattedStartDate = () => {
    if (!calendarRef || !calendarRef.current) {
      return '';
    }

    return moment(calendarRef.current._calendarApi.view.activeStart).format('DD-MM-YYYY');
  }

  const getFormattedEndDate = () => {
    if (!calendarRef || !calendarRef.current) {
      return '';
    }

    return moment(calendarRef.current._calendarApi.view.activeEnd).subtract(1, 'days').format('DD-MM-YYYY');
  }

  return (
    <Container fluid={true} className="calendar">
      <FullCalendar
        firstDay={1}
        datesSet={(view) => {
          updateItems();
          changeViewType(view.view.type);
        }}
        ref={calendarRef}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today adminButton excelButton invoiceButton',
          center: 'title',
          right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,"",logoutButton',
        }}
        resourceGroupLabelDidMount={(info) => {
          const groupValues = info.groupValue.split(' - ');
          const formattedValue = groupValues[1];

          info.el.querySelector('.fc-datagrid-expander').addEventListener('mousedown', function(e) {
            const path = e.path[0];
            let open = true;
            if (path.className.includes('minus')) {
              open = false;
            }

            toggleResourceGroupVisibility(formattedValue, open);
          });

          setTimeout(function() {
            const foundGroup = props.user.meta.resourceGroups.find(group => group.name == formattedValue);
            if (foundGroup !== undefined && foundGroup.visible === false) {
              const expander = info.el.querySelector('.fc-datagrid-expander');
              const minus = expander.querySelector('.fc-icon-minus-square');

              if (minus) {
                info.el.querySelector('.fc-datagrid-expander').click();
              }
            }
          }, 1000);

        }}
        views={{
          resourceTimelineDay: {
            buttonText: 'Dag',
            titleFormat: {
              year: 'numeric', month: 'long', day: 'numeric', weekday: 'short'
            },
          },
          resourceTimelineWeek: {
            buttonText: 'Week',
            duration: {weeks: 1},
            slotDuration: {days: 1}
          },
          resourceTimelineMonth: {
            duration: {days: 30},
            buttonText: 'Maand'
          }
        }}
        customButtons={{
          adminButton: {
            text: 'Admin',
            click: () => {
              history.push('/admin');
            }
          },
          excelButton: {
            text: 'Download excel',
            click: () => {
              const {activeStart, activeEnd} = calendarRef.current._calendarApi.view;
              props.itemActions.downloadExcel(activeStart, activeEnd, false, []).then(response => {
                if (response) {
                  FileDownload(response, 'Planning.xlsx');
                }
              });
            }
          },
          invoiceButton: {
            text: 'Factureren',
            click: () => {
              /*const {activeStart, activeEnd} = calendarRef.current._calendarApi.view;
              props.itemActions.downloadExcel(activeStart, activeEnd).then(response => {
                if (response) {
                  FileDownload(response, 'Planning.xlsx');
                }
              });*/
              setShowInvoiceModal(true);
            }
          },
          logoutButton: {
            text: 'Uitloggen',
            click: () => {
              props.authenticationActions.logout();
            }
          }
        }}
        initialView={"resourceTimelineWeek"}
        weekNumbers={true}
        aspectRatio={2}
        scrollTime={'00:00'}
        resourceGroupField='categoryDisplayOrder'
        resources={getFormattedResources()}
        resourceOrder={"categoryDisplayOrder, displayOrder"}
        resourceGroupLabelContent={(e) => {
          const groupValues = e.groupValue.split(' - ');

          return groupValues[1];
        }}
        resourceLabelContent={(e) => {
          const resourceId = parseInt(e.resource._resource.id);
          const resource = resources.find(resource => resource.id === resourceId);
          return (
            <Fragment>
              {resource.hasImage &&
              <img
                width={75}
                height={75}
                alt={"Image"}
                src={`${apiRoutes.rootUrl}resource/${resourceId}/image`}
                className="resourceImage"
              />
              }
              {resource.name}
              <Button
                size="sm"
                variant="secondary"
                className="float-right"
                onClick={() => handleResourceClick(parseInt(e.resource._resource.id))}
              >
                <Pencil/>
              </Button>
            </Fragment>
          )
        }}
        timeZone="Europe/Amsterdam"
        events={getFormattedItems()}
        resourceAreaHeaderContent="Middelen"
        locale={'nl'}
        editable={true}
        dateClick={(res) => {
          let item = {...emptyItem};
          item.start = new Date(res.date.getTime());
          item.start.setMinutes(item.start.getMinutes() + item.start.getTimezoneOffset());
          item.end = new Date(res.date.getTime());
          item.end.setMinutes(item.end.getMinutes() + item.end.getTimezoneOffset());

          item.start.setHours(0,1,0);
          item.end.setHours(23, 59, 59);

          item.planningResource = {
            id: parseInt(res.resource._resource.id)
          };
          item.projects = [{...emptyProject}]
          setSelectedItem(item);
          setShowModal(true);
        }}
        eventClick={(e) => {
          const item = items.find(item => item.id === parseInt(e.event._def.publicId));
          let newItem = {...item};
          newItem.start = new Date(item.start);
          newItem.start.setMinutes(newItem.start.getMinutes() + newItem.start.getTimezoneOffset())
          newItem.end = new Date(item.end);
          newItem.end.setMinutes(newItem.end.getMinutes() + newItem.end.getTimezoneOffset())
          setSelectedItem(newItem);
          setShowModal(true);
        }}
        eventDrop={(e) => {
          const id = parseInt(e.event._def.publicId);
          let dateRange = e.event._instance.range;
          dateRange.start.setMinutes(dateRange.start.getMinutes() + dateRange.start.getTimezoneOffset())
          dateRange.end.setMinutes(dateRange.end.getMinutes() + dateRange.end.getTimezoneOffset())

          let data = {
            start: moment(dateRange.start).format('YYYY-MM-DD HH:mm'),
            end: moment(dateRange.end).format('YYYY-MM-DD HH:mm')
          }

          if (e.newResource) {
            data.planningResource = parseInt(e.newResource._resource.id);
          }

          props.itemActions.patchItem(id, data);
        }}
        eventResize={(e) => {
          const id = parseInt(e.event._def.publicId);
          let dateRange = e.event._instance.range;
          dateRange.start.setMinutes(dateRange.start.getMinutes() + dateRange.start.getTimezoneOffset())
          dateRange.end.setMinutes(dateRange.end.getMinutes() + dateRange.end.getTimezoneOffset())

          const data = {
            start: moment(dateRange.start).format('YYYY-MM-DD HH:mm'),
            end: moment(dateRange.end).format('YYYY-MM-DD HH:mm')
          }
          props.itemActions.patchItem(id, data);
        }}
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {selectedItem.id ? 'Planning - ' + selectedItem.title + ' (' + selectedItem.id + ')' : 'Nieuwe planning'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlanningForm
            customers={customers}
            data={selectedItem}
            resources={resources}
            handleSubmit={handleSubmit}
            emptyProject={{...emptyProject}}
            deleteItem={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            products={props.products}
            productActions={props.productActions}
            downloadWeighingSlip={props.itemActions.downloadWeighingSlip}
            saving={saving}
            licensePlates={props.item.licensePlates}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showResourceModal}
        onHide={() => setShowResourceModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {selectedResource.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Resource
            resourceMaintenance={resourceMaintenance}
            resource={selectedResource}
            handleMaintenanceSubmit={handleMaintenanceSubmit}
            handleFileSubmit={handleFileSubmit}
            deleteFile={handleDeleteFile}
            resourceFile={resourceFile}
            downloadFile={downloadFile}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Factureren
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="link" onClick={() => selectAllResources()}>Selecteer alles</Button>
          <Button variant="link" onClick={() => deselectAllResources()}>Deselecteer alles</Button> <br /><br />
          {resources.sort((a,b) => a.name.localeCompare(b.name)).map(resource => {
            const checked = resourcesToInvoice && resourcesToInvoice.findIndex(res => res.id === resource.id) > -1;
            return (
              <div key={resource.id}>
                <Form.Check
                  inline
                  name={`resource-${resource.id}`}
                  label={resource.name}
                  type="checkbox"
                  id={`resource-${resource.id}`}
                  size={'sm'}
                  checked={checked}
                  onChange={(e) => {
                    const {checked} = e.target;
                    let newResourcesToInvoice = [...resourcesToInvoice];
                    if (checked) {
                      newResourcesToInvoice.push(resource);
                    } else {
                      newResourcesToInvoice = newResourcesToInvoice.filter(res => res.id !== resource.id);
                    }

                    setResourcesToInvoice(newResourcesToInvoice);
                  }}
                />
              </div>
            )
          })}

          <br /><br />

          <p>
            De items tussen de volgende datumrange zullen geexporteerd worden: <br />
            <strong>Startdatum: </strong> {getFormattedStartDate()} <br />
            <strong>Einddatum: </strong> {getFormattedEndDate()}
          </p>

          <Button
            variant="primary"
            className="float-right"
            onClick={() => {
              const {activeStart, activeEnd} = calendarRef.current._calendarApi.view;
              props.itemActions.downloadExcel(activeStart, activeEnd, true, resourcesToInvoice).then(response => {
                if (response) {
                  FileDownload(response, 'Planning.xlsx');
                }
              });
            }}
          >
            Factureren
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    resource: state.resource,
    item: state.item,
    customers: state.customer.allCustomers,
    user: state.user,
    products: state.product.products
  }
};

const mapDispatchToProps = dispatch => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    resourceActions: bindActionCreators(resourceActions, dispatch),
    itemActions: bindActionCreators(itemActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
