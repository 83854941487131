import {actionTypes} from '../constants/actionTypes';

const emptyLicensePlate = {
  id: '',
  licensePlate: '',
  defaultWeighing: '',
}

const initialState = {
  licensePlates: [],
  emptyLicensePlate: {...emptyLicensePlate},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.licensePlateRelated.GET_LICENSE_PLATES_LIST:
      return {
        ...state,
        licensePlates: action.response
      };
    case actionTypes.licensePlateRelated.RESET_LICENSE_PLATES:
      return {
        ...state,
        emptyLicensePlate: {...emptyLicensePlate}
      }
    default:
      return state;
  }
};
