import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  resources: [],
  categories: [],
  emptyResource: {
    name: '',
    color: '',
    category: null,
    imageFile: ''
  },
  emptyCategory: {
    name: ''
  },
  emptyResourceMaintenance: {
    kilometres: '',
    date: new Date(),
    fullService: false,
    smallService: false,
    examination: false,
    carriedOutBy: '',
    activities: '',
    remarks: '',
    done: false,
    motorLitres: 0,
    hydraulicLitres: 0
  },
}

let categories = [];
let resources = [];

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.resourceRelated.GET_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: action.response
      };
    case actionTypes.resourceRelated.POST_RESOURCE_SUCCESS:
      resources = [...state.resources];
      resources.push(action.response.resource);
      return {
        ...state,
        resources: resources
      };
    case actionTypes.resourceRelated.PATCH_RESOURCE_SUCCESS:
      let patchResources = [...state.resources];
      let foundIndex = patchResources.findIndex(resource => resource.id === action.response.resource.id);

      if(foundIndex > -1) {
        patchResources[foundIndex] = action.response.resource;
      }

      return {
        ...state,
        resources: patchResources
      };
    case actionTypes.resourceRelated.POST_MAINTENANCE_SUCCESS:
      let maintenanceResources = [...state.resources];
      let foundIndex2 = maintenanceResources.findIndex(resource =>
        resource.id === action.response.maintenance.planningResource.id
      );
      if(foundIndex2 > -1) {
        maintenanceResources[foundIndex2].maintenances.push(action.response.maintenance);
      }
      return {
        ...state,
        resources: maintenanceResources
      };
    case actionTypes.resourceRelated.POST_RESOURCE_FILE_SUCCESS:
      let fileResources = [...state.resources];
      let foundIndex3 = fileResources.findIndex(resource => resource.id === action.resourceId);
      if (foundIndex3 > -1) {
        fileResources[foundIndex3].files.push(action.response.file);
      }
      return {
        ...state,
        resources: fileResources
      };
    case actionTypes.resourceRelated.GET_RESOURCE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.response
      };
    case actionTypes.resourceRelated.POST_RESOURCE_CATEGORY_SUCCESS:
      categories = [...state.categories];
      categories.push(action.response.category);
      return {
        ...state,
        categories: categories
      };
    case actionTypes.resourceRelated.PATCH_RESOURCE_CATEGORY_SUCCESS:
      categories = [...state.categories];
      let foundPatchIndex = categories.findIndex(category => category.id === action.response.category.id);

      if(foundPatchIndex > -1) {
        categories[foundPatchIndex] = action.response.category;
      }
      return {
        ...state,
        categories: categories
      };
    case actionTypes.resourceRelated.DELETE_RESOURCE_CATEGORY_SUCCESS:
      categories = [...state.categories];
      resources = [...state.resources];

      resources.forEach((resource, key) => {
        if(resource.category && resource.category.id === action.id) {
          resources[key]['category'] = null;
        }
      });

      return {
        ...state,
        categories: categories.filter(category => category.id !== action.id),
        resources: resources
      }
    case actionTypes.resourceRelated.PATCH_MAINTENANCE_SUCCESS:
      resources = [...state.resources];

      resources.forEach((resource, resourceKey) => {
        resource.maintenances.forEach((maintenance, maintenanceKey) => {
          if (maintenance.id === action.response.maintenance.id) {
            resources[resourceKey]['maintenances'][maintenanceKey] = action.response.maintenance;
          }
        });
      });

      return {
        ...state,
        resources: resources
      };
    case actionTypes.resourceRelated.DELETE_RESOURCE_FILE_SUCCESS:
      resources = [...state.resources];

      resources.forEach((resource, resourceKey) => {
        resources[resourceKey]['files'] = resource.files.filter(file => file.id !== action.id);
      })

      return {
        ...state,
        resources: resources
      }
    case actionTypes.resourceRelated.DELETE_RESOURCE_SUCCESS:
      resources = [...state.resources];

      return {
        ...state,
        resources: resources.filter(resource => resource.id !== action.id)
      }
    case actionTypes.resourceRelated.SET_RESOURCES:
      return {
        ...state,
        resources: action.resources
      };
    case actionTypes.resourceRelated.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    default:
      return state;
  }
}
