import React, {Fragment, useEffect, useState} from 'react';
import {Container, Row, Col, Button} from "react-bootstrap";
import {connect} from "react-redux";
import resourceActions from "../../../actions/resourceActions";
import {bindActionCreators} from "redux";
import ResourceForm from "./resourceForm";
import CategoryForm from "./categoryForm";
import { ArrowsMove } from 'react-bootstrap-icons';
import {ReactSortable} from "react-sortablejs";
import {Trash, Pencil} from 'react-bootstrap-icons';

const Resources = (props) => {

  const { emptyResource, emptyCategory, categories } = props.resource;

  const [showModal, setShowModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const [selectedResource, setSelectedResource] = useState(emptyResource);
  const [selectedCategory, setSelectedCategory] = useState(emptyCategory);
  const [resources, setResources] = useState([]);
  const [orderTask, setOrderTask] = useState({});

  useEffect(() => {
    props.actions.getCategories();
    props.actions.getResources();
  }, []);

  useEffect(() => {
    setResources(props.resource.resources);
  }, [props.resource.resources]);

  const handleResourceSubmit = (values) => {
    let data = {...values};
    data.category = values.category ? values.category.id : null;
    if(data.id) {
      delete data.id;
      props.actions.patchResource(values.id, data).then(() => setShowModal(false));
    } else {
      props.actions.postResource(data).then(() => setShowModal(false));
    }
  };

  const handleCategorySubmit = (values) => {
    let data = {...values};
    if(data.id) {
      delete data.id;
      props.actions.patchCategory(values.id, data).then(() => setShowCategoryModal(false));
    } else {
      props.actions.postCategory(data).then(() => setShowCategoryModal(false));
    }
  };

  const updateCategories = (categories) => {
    props.actions.setCategories(categories);
  };

  const persistCategoryOrder = () => {
    props.actions.postCategoryOrder(categories);
  };

  const updateResources = (updatedResources, categoryId) => {

    if (updatedResources.length <= 0) {
      return;
    }

    let newResources = [...resources];
    newResources = newResources.filter(resource => {
      if (categoryId && resource.category && resource.category.id !== categoryId) {
        return true;
      }

      if (categoryId && !resource.category) {
        return true;
      }

      if (resource.category && !categoryId) {
        return true;
      }

      return false;
    });

    newResources = [...newResources, ...updatedResources];

    props.actions.setResources(newResources);

    // React sortable-js has no method to update list after drop, so we create an order task
    // and fire it after drop event
    setOrderTask({
      categoryId: categoryId,
      resources: updatedResources
    });
  };

  const persistOrders = () => {
    props.actions.postResourceOrder(orderTask.resources, orderTask.categoryId);
  }

  return (
    <Container fluid>
      <h2>Middelen</h2>

      <Button
        variant="primary"
        onClick={() => {
          setSelectedResource(emptyResource);
          setShowModal(true);
        }}
      >
        Voeg middel toe
      </Button> &nbsp;

      <Button
        variant="secondary"
        size="sm"
        onClick={() => {
          setSelectedResource(emptyCategory);
          setShowCategoryModal(true);
        }}
      >
        Voeg categorie toe
      </Button>

      <br /><br />

      <ReactSortable
        list={categories}
        setList={updateCategories}
        tag='div'
        handle='.category-handle'
        onEnd={() => persistCategoryOrder()}
      >
        {categories.map(category =>
          <div key={category.id}>
            <Row style={{padding: 5, backgroundColor: '#ccc'}}>
              <Col sm={8}>
                <strong>{category.name}</strong>
              </Col>
              <Col sm={3}>
                <Button
                  size='sm'
                  variant="secondary"
                  onClick={() => {
                    setSelectedCategory(category);
                    setShowCategoryModal(true);
                  }}
                >
                  <Pencil />
                </Button> &nbsp;

                <Button
                  size='sm'
                  variant="danger"
                  onClick={() => {
                    props.actions.deleteCategory(category.id);
                  }}
                >
                  <Trash/>
                </Button>
              </Col>
              <Col sm={1}>
                <div className='category-handle' style={{cursor: 'all-scroll'}}>
                  <ArrowsMove size={20} />
                </div>
              </Col>
            </Row>

            <ReactSortable
              list={resources.filter(resource => resource.category && resource.category.id === category.id)}
              setList={(newResources) => updateResources(newResources, category.id)}
              tag='div'
              handle='.item-handle'
              onEnd={() => persistOrders()}
            >
              {resources.filter(resource => resource.category && resource.category.id === category.id).map(resource =>
                <div key={resource.id}>
                  <Row style={{padding: 5}}>
                    <Col sm={8}>
                      - {resource.name}
                    </Col>
                    <Col sm={2}>
                      <Button
                        size='sm'
                        variant="secondary"
                        onClick={() => {
                          setSelectedResource(resource);
                          setShowModal(true);
                        }}
                      >
                        <Pencil />
                      </Button>
                      &nbsp;
                      <Button
                        size='sm'
                        variant="danger"
                        onClick={() => {
                          if (window.confirm('Weet je het zeker?')) {
                            props.actions.deleteResource(resource.id);
                          }
                        }}
                      >
                        <Trash/>
                      </Button>
                    </Col>
                    <Col sm={2}>
                      <div className='item-handle' style={{cursor: 'all-scroll'}}>
                        <ArrowsMove size={20} />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </ReactSortable>
          </div>
        )}
      </ReactSortable>


      <Row style={{padding: 5, backgroundColor: '#ccc'}}>
        <Col>
          <strong>Geen categorie</strong>
        </Col>
      </Row>
      <ReactSortable
        list={resources.filter(resource => resource.category === null)}
        setList={(newResources) => updateResources(newResources, null)}
        tag='div'
        handle='.item-handle'
        onEnd={() => persistOrders()}
      >
        {resources.filter(resource => resource.category === null).map(resource =>
          <div key={resource.id}>
            <Row style={{padding: 5}}>
              <Col sm={8}>
                - {resource.name}
              </Col>
              <Col sm={2}>
                <Button
                  size='sm'
                  variant="secondary"
                  onClick={() => {
                    setSelectedResource(resource);
                    setShowModal(true);
                  }}
                >
                  <Pencil />
                </Button>
                &nbsp;
                <Button
                  size='sm'
                  variant="danger"
                  onClick={() => {
                    if (window.confirm('Weet je het zeker?')) {
                      props.actions.deleteResource(resource.id);
                    }
                  }}
                >
                  <Trash/>
                </Button>
              </Col>
              <Col sm={2}>
                <div className='item-handle' style={{cursor: 'all-scroll'}}>
                  <ArrowsMove size={20} />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </ReactSortable>
      {/*

      <Table striped bordered hover>
        <thead>
        <tr>
          <th>#</th>
          <th>Naam</th>
          <th>Kleur</th>
          <th>Acties</th>
        </tr>
        </thead>


        {categories.map(category =>
          <Fragment key={category.id}>
            <tr style={{backgroundColor: '#F1F1F1'}}>
              <td colSpan={3}><strong>{category.name}</strong></td>
              <td>
                <Button
                  size='sm'
                  variant="secondary"
                  onClick={() => {
                    setSelectedCategory(category);
                    setShowCategoryModal(true);
                  }}
                >
                  <Pencil />
                </Button> &nbsp;

                <Button
                  size='sm'
                  variant="danger"
                  onClick={() => {
                    props.actions.deleteCategory(category.id);
                  }}
                >
                  <Trash/>
                </Button>
              </td>
            </tr>
            {resources.filter(resource => resource.category && resource.category.id === category.id).map(resource =>
              <tr key={resource.id}>
                <td>{resource.id}</td>
                <td>{resource.name}</td>
                <td>
                  {resource.color &&
                  <div style={{width: 200, height: 25, backgroundColor: resource.color, border: '1px solid #000'}} />
                  }
                </td>
                <td>
                  <Button
                    size='sm'
                    variant="secondary"
                    onClick={() => {
                      setSelectedResource(resource);
                      setShowModal(true);
                    }}
                  >
                    <Pencil />
                  </Button>
                  &nbsp;
                  <Button
                    size='sm'
                    variant="danger"
                    onClick={() => {
                      if (window.confirm('Weet je het zeker?')) {
                        props.actions.deleteResource(resource.id);
                      }
                    }}
                  >
                    <Trash/>
                  </Button>
                </td>
              </tr>
            )}
          </Fragment>
        )}

        <tr>
          <td style={{backgroundColor: '#F1F1F1'}} colSpan={4}><strong>Geen categorie</strong></td>
        </tr>

        {resources.filter(resource => resource.category === null).map(resource =>
          <tr key={resource.id}>
            <td>{resource.id}</td>
            <td>{resource.name}</td>
            <td>
              {resource.color &&
                <div style={{width: 200, height: 25, backgroundColor: resource.color, border: '1px solid #000'}} />
              }
            </td>
            <td>
              <Button
                size='sm'
                variant="secondary"
                onClick={() => {
                  setSelectedResource(resource);
                  setShowModal(true);
                }}
              >
                <Pencil />
              </Button>
              &nbsp;
              <Button
                size='sm'
                variant="danger"
                onClick={() => {
                  if (window.confirm('Weet je het zeker?')) {
                    props.actions.deleteResource(resource.id);
                  }
                }}
              >
                <Trash/>
              </Button>
            </td>
          </tr>
        )}
      </Table>
      */}

      <ResourceForm
        showModal={showModal}
        setShowModal={setShowModal}
        selectedResource={selectedResource}
        handleSubmit={handleResourceSubmit}
        categories={categories}
      />

      <CategoryForm
        showModal={showCategoryModal}
        setShowModal={setShowCategoryModal}
        selectedCategory={selectedCategory}
        handleSubmit={handleCategorySubmit}
      />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    resource: state.resource
  }
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(resourceActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
