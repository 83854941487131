import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Form, Table} from "react-bootstrap";
import {Formik} from "formik";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead, Hint } from 'react-bootstrap-typeahead';
import {Trash} from "react-bootstrap-icons";
import {Check} from "react-bootstrap-icons";
import noticeActions from "../../actions/noticeActions";


const PlanningForm = (props) => {

  return (
    <Formik
      initialValues={props.data}
      enableReinitialize={true}
      validationSchema={false}
      onSubmit={(values, {setErrors}) => {
        props.handleSubmit(values);
      }}
    >
      {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue
        }) => {
        const addProject = () => {
          let projects = [...values.projects];
          projects.push({...props.emptyProject});

          setFieldValue('projects', projects);
        }

        const handleProjectChange = (e, index) => {
          let projects = [...values.projects];
          const {name,value,type,checked} = e.target;

          if (type === 'checkbox') {
            projects[index][name] = checked;
          } else {
            projects[index][name] = value;
          }

          setFieldValue('projects', projects);
        };

        const getSelectedCustomer = (single = false) => {
          const selectedCustomer = getCustomerOptions().find(customer => values.customer === customer.id);

          if (selectedCustomer !== undefined) {
            if (single) { return selectedCustomer};
            return [selectedCustomer];
          } else {
            return null;
          }
        };

        const getFormattedProducts = () => {
          return props.products.map(product => (
            {
              id: product.id,
              name: product.searchName + ' - ' + product.description,
              price: product.price,
              unit: product.unit
            }
          ));
        };

        const getSelectedProduct = (index) => {
          const products = getFormattedProducts();
          const selectedProduct = products.find(product => product.id === values.projects[index]['product']);

          if (selectedProduct !== undefined) {
            return [selectedProduct];
          } else {
            return null;
          }
        };

        const getSelectedLicensePlate = (index) => {
          const selectedLicensePlate = props.licensePlates.find(plate => plate.licensePlate === values.projects[index]['licensePlate']);

          if (selectedLicensePlate !== undefined) {
            return [selectedLicensePlate];
          } else {
            return null;
          }
        };

        const deleteProject = (index) => {
          let newProjects = [...values.projects];
          newProjects = newProjects.filter((project, key) => key !== index);

          setFieldValue('projects', newProjects);
        };

        const getCustomerOptions = () => {
          return props.customers.map(item => {
            return {
              ...item,
              nameWithAddress: item.name + ' / ' + item.addressLine1 + ' / ' + item.city
            }
          });
        };

        const customer = getSelectedCustomer(true);

        return (
          <Form onSubmit={handleSubmit} className="compact-form" style={{fontSize: 14}}>

            <Row>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Zoek klant</Form.Label>
                  <Col lg={9}>
                    <Typeahead
                      name={'testasdf'}
                      className="basic-typeahead-single-customer"
                      labelKey="nameWithAddress"
                      onChange={(data) => {
                        let customer = data[0];

                        if (customer === undefined) {
                          setFieldValue('customer', null);
                          setFieldValue('email', '');
                          setFieldValue('invoiceAddress', '');
                          setFieldValue('title', '');
                          setFieldValue('phoneNumber', '');
                        } else {
                          setFieldValue('customer', customer.id);
                          setFieldValue('title', customer.name);
                          setFieldValue('email', customer.email);
                          setFieldValue('invoiceAddress', customer.addressLine1 + ' ' + customer.city);
                          setFieldValue('phoneNumber', customer.phoneNumber);
                          setFieldValue('postalCode', customer.postalCode);
                        }
                      }}
                      options={getCustomerOptions()}
                      placeholder="Kies een klant"
                      selected={getSelectedCustomer()}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Naam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'title'}
                      readOnly={false}
                      placeholder='Naam'
                      type={'text'}
                      name={'title'}
                      value={values.title}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Email</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'email'}
                      readOnly={false}
                      placeholder='Email'
                      type={'text'}
                      name={'email'}
                      value={values.email}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Telefoonnummer</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'phoneNumber'}
                      readOnly={false}
                      placeholder='Telefoonnummer'
                      type={'text'}
                      name={'phoneNumber'}
                      value={values.phoneNumber}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Postcode</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'postalCode'}
                      readOnly={false}
                      placeholder='Postcode'
                      type={'text'}
                      name={'postalCode'}
                      value={values.postalCode}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Factuuradres</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'invoiceAddress'}
                      readOnly={false}
                      placeholder='Factuuradres'
                      type={'text'}
                      name={'invoiceAddress'}
                      value={values.invoiceAddress}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>


                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Werknummer</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'workNumber'}
                      readOnly={false}
                      placeholder='Werknummer'
                      type={'text'}
                      name={'workNumber'}
                      value={values.workNumber}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Naam schip</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'nameShip'}
                      readOnly={false}
                      placeholder='Naam schip'
                      type={'text'}
                      name={'nameShip'}
                      value={values.nameShip}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Naam contactpersoon</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'nameContactPerson'}
                      readOnly={false}
                      placeholder='Naam contactpersoon'
                      type={'text'}
                      name={'nameContactPerson'}
                      value={values.nameContactPerson}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Naam chauffeur/machinist</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'nameDriver'}
                      readOnly={false}
                      placeholder='Naam chauffeur/machinist'
                      type={'text'}
                      name={'nameDriver'}
                      value={values.nameDriver}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm={2}>
                    <Form.Check
                      inline
                      name="agreeDriver"
                      label="Akkoord chauffeur"
                      type="checkbox"
                      id="agreeDriver"
                      checked={values.agreeDriver}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      id={'emailDriver'}
                      readOnly={false}
                      placeholder='E-mailadres'
                      type={'text'}
                      name={'emailDriver'}
                      value={values.emailDriver}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      inline
                      name="agreeCustomer"
                      label="Akkoord klant"
                      type="checkbox"
                      id="agreeCustomer"
                      checked={values.agreeCustomer}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      id={'emailCustomer'}
                      readOnly={false}
                      placeholder='E-mailadres'
                      type={'text'}
                      name={'emailCustomer'}
                      value={values.emailCustomer}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

              </Col>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Resource</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      as="select"
                      value={values.planningResource.id}
                      onChange={(e) => {
                        setFieldValue('planningResource', {id: parseInt(e.target.value)});
                      }}
                      size={'sm'}
                    >
                      {props.resources.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(resource =>
                        <option key={resource.id} value={resource.id}>{resource.name}</option>
                      )}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Start datum</Form.Label>
                  <Col lg={9}>
                    <DatePicker
                      selected={values.start}
                      onChange={(date) => setFieldValue('start', date)}
                      className={'form-control form-control'}
                      dateFormat="dd-MM-yyyy HH:mm"
                      disabledKeyboardNavigation
                      tabIndex={-1}
                      showTimeInput
                      locale='nl'
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Eind datum</Form.Label>
                  <Col lg={9}>
                    <DatePicker
                      selected={values.end}
                      onChange={(date) => setFieldValue('end', date)}
                      className={'form-control form-control'}
                      dateFormat="dd-MM-yyyy HH:mm"
                      disabledKeyboardNavigation
                      tabIndex={-1}
                      showTimeInput
                      locale='nl'
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Aan- en afvoer</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'drain'}
                      readOnly={false}
                      placeholder='Aan- en afvoer'
                      type={'text'}
                      name={'drain'}
                      value={values.drain}
                      onChange={handleChange}
                      size={'sm'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Oplevering schip</Form.Label>

                  <Form.Check
                    inline
                    label="Knijperschoon"
                    type="checkbox"
                    id="knijperschoon"
                    name="cleanKnijper"
                    checked={values.cleanKnijper}
                    onChange={handleChange}
                  />

                  <Form.Check
                    inline
                    label="Bobcatschoon"
                    type="checkbox"
                    id="bobcatschoon"
                    name="cleanBobCat"
                    checked={values.cleanBobCat}
                    onChange={handleChange}
                  />

                  <Form.Check
                    inline
                    name="cleanVeeg"
                    label="Veegschoon"
                    type="checkbox"
                    id="veegschoon"
                    checked={values.cleanVeeg}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Opmerkingen</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    size={'sm'}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{marginBottom: 30}}>
              <Col>
                <Table>
                  <thead>
                  <tr>
                    <th>Project</th>
                    <th>Start tijd</th>
                    <th>Eind tijd</th>
                    <th>Product</th>
                    <th>Aantal</th>
                    <th>Eenheid</th>
                    <th>Prijs p/s / contant</th>
                    <th>Contant</th>
                    <th>Eerste weging</th>
                    <th>Tweede weging</th>
                    <th>Nettogewicht</th>
                    <th>Vervoerder</th>
                    <th>Kenteken</th>
                    <th>Reeds betaald?</th>
                    <th>&nbsp;</th>
                  </tr>
                  {values.projects.map((project, key) =>
                    <tr key={key}>
                      <td>
                        <Form.Control
                          name="description"
                          as="textarea"
                          rows="3"
                          value={project.description}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="startTime"
                          type={'text'}
                          value={project.startTime}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="endTime"
                          type={'text'}
                          value={project.endTime}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        <Typeahead
                          className="product-select"
                          name={'testasdf'}
                          id="basic-typeahead-single"
                          labelKey="name"
                          onChange={(data) => {
                            let product = data[0];
                            let e = {
                              target: {
                                name: 'product',
                                value: product === undefined ? null : product.id
                              }
                            };

                            handleProjectChange(e, key);

                            // Change unit
                            e = {
                              target: {
                                name: 'unit',
                                value: product === undefined ? '' : product.unit
                              }
                            };

                            console.log(e);

                            handleProjectChange(e, key);

                            // Retrieve product price
                            let productPrice = 0;
                            if (values.customer && e.target.value) {
                              props.productActions.getPrice(values.customer, e.target.value).then((response) => {
                                if (response) {
                                  productPrice = response.price - response.discount;
                                } else {
                                  productPrice = product === undefined ? 0 : product.price
                                }

                                e = {
                                  target: {
                                    name: 'productPrice',
                                    value: productPrice
                                  }
                                };

                                handleProjectChange(e, key);
                              });
                            }
                          }}
                          options={getFormattedProducts()}
                          placeholder="Selecteer een product"
                          selected={getSelectedProduct(key)}
                          defaultSelected={[]}
                          size={'sm'}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="m3"
                          type={'text'}
                          value={project.m3}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          as="select"
                          name='unit'
                          value={project.unit}
                          onChange={(e) => handleProjectChange(e, key)}
                        >
                          <option value={null}>Selecteer</option>
                          <option value={'m3'}>m3</option>
                          <option value={'kg'}>kg</option>
                          <option value={'st'}>st</option>
                          <option value={'st.'}>st.</option>
                          <option value={'ton'}>ton</option>
                          <option value={'uur'}>uur</option>
                        </Form.Control>
                      </td>
                      <td>
                        <Form.Control
                          name="productPrice"
                          type={'number'}
                          value={project.productPrice}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        <Form.Check
                          inline
                          name="cash"
                          type="checkbox"
                          id="cash"
                          checked={project.cash}
                          onChange={(e) => handleProjectChange(e, key)}
                          size={'sm'}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="firstWeighting"
                          type={'text'}
                          value={project.firstWeighting}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="secondWeighting"
                          type={'text'}
                          value={project.secondWeighting}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        {project.secondWeighting-project.firstWeighting}
                      </td>
                      <td>
                        <Form.Control
                          name="carrier"
                          type={'text'}
                          value={project.carrier}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />
                      </td>
                      <td>
                        {/*<Form.Control
                          name="licensePlate"
                          type={'text'}
                          value={project.licensePlate}
                          size="sm"
                          onChange={(e) => handleProjectChange(e, key)}
                        />*/}
                        <Typeahead
                          className="product-select"
                          name={'licensePlate'}
                          id="basic-typeahead-single"
                          labelKey="licensePlate"
                          onChange={(data) => {
                            let licensePlate = data[0];
                            let e = {
                              target: {
                                name: 'licensePlate',
                                value: licensePlate === undefined ? null : licensePlate.licensePlate
                              }
                            };

                            handleProjectChange(e, key);

                            e = {
                              target: {
                                name: 'firstWeighting',
                                value: licensePlate === undefined ? 0 : licensePlate.defaultWeighing
                              }
                            }

                            handleProjectChange(e, key);
                          }}
                          onInputChange={(text) => {
                            const e = {
                              target: {
                                name: 'licensePlate',
                                value: text
                              }
                            };

                            handleProjectChange(e, key);
                          }}
                          options={props.licensePlates}
                          placeholder=""
                          defaultInputValue={project.licensePlate ?? ''}
                          //selected={getSelectedLicensePlate(key)}
                          //defaultSelected={[]}
                          size={'sm'}
                        />
                      </td>
                      <td>
                        <Form.Check
                          inline
                          name="paid"
                          type="checkbox"
                          id="paid"
                          checked={project.paid}
                          onChange={(e) => handleProjectChange(e, key)}
                          size={'sm'}
                        />
                      </td>
                      <td>
                        <Button size="sm" variant="danger" onClick={() => {
                          if (window.confirm('Weet je het zeker?')) {
                            deleteProject(key);
                          }
                        }}><Trash/></Button>
                      </td>
                    </tr>
                  )}
                  </thead>
                </Table>

                <Button
                  size='sm'
                  variant="success"
                  className="float-left"
                  onClick={() => addProject()}
                >
                  Voeg project toe
                </Button>
              </Col>
            </Row>

            {values.id &&
              <Button
                variant="danger"
                type="button"
                className="float-left"
                onClick={() => {
                  if (window.confirm('Weet je zeker dat je dit item wilt verwijderen?')) {
                    props.deleteItem(values.id);
                  }
                }}
              >
                Verwijder
              </Button>
            }
            {values.id &&
            <Button
              variant="secondary"
              type="button"
              className="float-left"
              onClick={() => {
                if (window.confirm('Weet je zeker dat je dit item wilt dupliceren?')) {
                  props.duplicateItem(values);
                }
              }}
              style={{marginLeft: 10}}
            >
              Dupliceer
            </Button>
            }

            <Button
              variant="primary"
              type="submit"
              className="float-right"
              style={{marginLeft: 20}}
              disabled={props.saving}
            >
              Opslaan
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="float-right"
              onClick={() => {
                props.handleSubmit(values, true, 'excel');
              }}
              disabled={props.saving}
            >
              Opslaan & weegbon excel
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="float-right"
              onClick={() => {
                props.handleSubmit(values, true, 'pdf');
              }}
              disabled={props.saving}
              style={{marginRight: 10}}
            >
              Opslaan & weegbon PDF
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="float-right"
              onClick={() => {
                let email = customer.weighingSlipEmail;
                if (!email) {
                  email = customer.email;
                }
                if (window.confirm('De bon zal gemaild worden naar: ' + email)) {
                  props.handleSubmit(values, false, 'pdf', true);
                }
              }}
              disabled={props.saving || !customer || (!customer.email && !customer.weighingSlipEmail)}
              style={{marginRight: 10}}
            >
              Opslaan & email {values.emailed && <Check />}
            </Button>
            &nbsp;
          </Form>
        )
      }}
    </Formik>
  )
}

export default PlanningForm;
