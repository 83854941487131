import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getResources = () => {
  return async dispatch => {
    try
    {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.resourceRelated.list);

      dispatch({
        type: actionTypes.resourceRelated.GET_RESOURCES_SUCCESS,
        response
      })
    }
    catch (error)
    {
      toastr.error('Kon de resource lijst niet ophalen');

      dispatch({
        type: actionTypes.resourceRelated.GET_RESOURCES_FAIL
      });
    }
  }
};

const postResource = (resource) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.resourceRelated.post, resource);

      dispatch({
        type: actionTypes.resourceRelated.POST_RESOURCE_SUCCESS,
        response
      });

      toastr.success('Middel opgeslagen!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon het middel niet opslaan');

      dispatch({
        type: actionTypes.resourceRelated.POST_RESOURCE_FAIL
      });
    }
  }
};

const deleteResource = (resourceId) => {
  return async dispatch => {
    try
    {
      const url = apiRoutes.resourceRelated.delete.replace('{resourceId}', resourceId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      toastr.success('Middel verwijderd!');

      dispatch({
        type: actionTypes.resourceRelated.DELETE_RESOURCE_SUCCESS,
        id: resourceId
      })
    }
    catch (error)
    {
      toastr.error('Kan het middel niet verwijderen');
    }
  }
};

const patchResource = (resourceId, resource) => {
  return async dispatch => {
    try {
      const url = apiRoutes.resourceRelated.patch.replace('{resourceId}', resourceId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, resource);

      dispatch({
        type: actionTypes.resourceRelated.PATCH_RESOURCE_SUCCESS,
        response
      });

      toastr.success('Resource opgeslagen!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon de resource niet opslaan');

      dispatch({
        type: actionTypes.resourceRelated.PATCH_RESOURCE_FAIL
      });
    }
  }
}

const postMaintenance = (maintenance) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.resourceRelated.postMaintenance, maintenance);

      dispatch({
        type: actionTypes.resourceRelated.POST_MAINTENANCE_SUCCESS,
        response
      });

      toastr.success('Onderhoud opgeslagen!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon het onderhoud niet opslaan');

      dispatch({
        type: actionTypes.resourceRelated.POST_MAINTENANCE_FAIL
      });
    }
  }
}

const patchMaintenance = (id, data) => {
  return async dispatch => {
    try {
      const url = apiRoutes.resourceRelated.patchMaintenance.replace('{maintenanceId}', id);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch({
        type: actionTypes.resourceRelated.PATCH_MAINTENANCE_SUCCESS,
        response
      });

      toastr.success('Onderhoud opgeslagen!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon het onderhoud niet opslaan');
    }
  }
}

const postFile = (resourceId, data) => {
  return async dispatch => {
    try {
      const url = apiRoutes.resourceRelated.postFile.replace('{resourceId}', resourceId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch({
        type: actionTypes.resourceRelated.POST_RESOURCE_FILE_SUCCESS,
        response: response,
        resourceId: resourceId
      });

      toastr.success('Bestand toegevoegd!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon het bestand niet opslaan');

      dispatch({
        type: actionTypes.resourceRelated.POST_RESOURCE_FILE_FAIL
      });
    }
  }
}

const deleteFile = (fileId) => {
  return async dispatch => {
    try
    {
      const url = apiRoutes.resourceRelated.deleteFile.replace('{file}', fileId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch({
        type: actionTypes.resourceRelated.DELETE_RESOURCE_FILE_SUCCESS,
        id: fileId
      })
    }
    catch (error)
    {
      toastr.error('Kan het bestand niet verwijderen');
    }
  }
};

const downloadFile = (resourceId, fileId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.resourceRelated.getFile.replace('{resourceId}', resourceId).replace('{file}', fileId);

      return await ApiService.performRequest(RequestType.GET_FILE_ARRAY, url);
    }
    catch (error)
    {
      toastr.error('Kan bestand niet downloaden');
    }
  }
}

const postCategory = (category) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.resourceRelated.postCategory, category);

      dispatch({
        type: actionTypes.resourceRelated.POST_RESOURCE_CATEGORY_SUCCESS,
        response
      });

      toastr.success('Categorie toegevoegd!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon de categorie niet toevoegen');

      dispatch({
        type: actionTypes.resourceRelated.POST_RESOURCE_CATEGORY_FAIL
      });
    }
  }
}

const patchCategory = (categoryId, category) => {
  return async dispatch => {
    try {
      const url = apiRoutes.resourceRelated.patchCategory.replace('{categoryId}', categoryId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, category);

      dispatch({
        type: actionTypes.resourceRelated.PATCH_RESOURCE_CATEGORY_SUCCESS,
        response
      });

      toastr.success('Categorie bewerkt!');

      return response;
    }
    catch (error)
    {
      toastr.error('Kon de categorie niet bewerken');

      dispatch({
        type: actionTypes.resourceRelated.PATCH_RESOURCE_CATEGORY_FAIL
      });
    }
  }
}

const deleteCategory = (categoryId) => {
  return async dispatch => {
    try
    {
      const url = apiRoutes.resourceRelated.patchCategory.replace('{categoryId}', categoryId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch({
        type: actionTypes.resourceRelated.DELETE_RESOURCE_CATEGORY_SUCCESS,
        id: categoryId
      })
    }
    catch (error)
    {
      toastr.error('Kan de categorie niet verwijderen');
    }
  }
};

const getCategories = () => {
  return async dispatch => {
    try
    {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.resourceRelated.listCategories);

      dispatch({
        type: actionTypes.resourceRelated.GET_RESOURCE_CATEGORIES_SUCCESS,
        response
      })
    }
    catch (error)
    {
      dispatch({
        type: actionTypes.resourceRelated.GET_RESOURCE_CATEGORIES_FAIL
      });
    }
  }
};

const setResources = (resources) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.resourceRelated.SET_RESOURCES,
      resources
    })
  }
};

const setCategories = (categories) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.resourceRelated.SET_CATEGORIES,
      categories
    })
  }
};

const postResourceOrder = (resources, categoryId) => {
  return async dispatch => {
    try {
      const data = {
        categoryId: categoryId,
        resources: resources
      }
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.resourceRelated.order, data);

      toastr.success('Volgorde veranderd');
    }
    catch (error)
    {
      toastr.error('Kon volgorde niet veranderen');
    }
  }
}

const postCategoryOrder = (categories) => {
  return async dispatch => {
    try {
      const data = {
        categories: categories
      }
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.resourceRelated.order, data);

      toastr.success('Volgorde veranderd');
    }
    catch (error)
    {
      toastr.error('Kon volgorde niet veranderen');
    }
  }
}

export default {
  getResources,
  postResource,
  patchResource,
  postMaintenance,
  patchMaintenance,
  postFile,
  postCategory,
  patchCategory,
  getCategories,
  deleteCategory,
  deleteFile,
  deleteResource,
  setResources,
  postResourceOrder,
  setCategories,
  postCategoryOrder,
  downloadFile
}
