import RequestType from "../constants/requestType";

import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'


const getList = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.licensePlateRelated.list);

      dispatch({
        type: actionTypes.licensePlateRelated.GET_LICENSE_PLATES_LIST,
        response
      });
    }
    catch (error)
    {
      toastr.error('Error loading Kenteken');
    }
  }
};

const postLicensePlate = (data) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.licensePlateRelated.post, data);

      dispatch({
        type: actionTypes.licensePlateRelated.POST_LICENSE_PLATES_SUCCESS,
        response
      });

      toastr.success("Kenteken succesvol toegevoegd")
    }
    catch (error)
    {
      console.log(error);
      toastr.error('Error saving Kenteken');
    }
  }
};

const patchLicensePlate = (licensePlateId, data) => {
  return async dispatch => {
    try {
      const url = apiRoutes.licensePlateRelated.patch.replace('{licensePlateId}', licensePlateId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch({
        type: actionTypes.licensePlateRelated.PATCH_LICENSE_PLATES_SUCCESS,
        response
      });

      toastr.success("Kenteken succesvol bijgewerkt")
    }
    catch (error)
    {
      toastr.error('Error saving Kenteken');
    }
  }
};

const deleteLicensePlate = (licensePlateId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.licensePlateRelated.patch.replace('{licensePlateId}', licensePlateId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch({
        type: actionTypes.licensePlateRelated.DELETE_LICENSE_PLATES_SUCCESS,
        response
      });

      toastr.success("Kenteken verwijderd")
    }
    catch (error)
    {
      toastr.error('Error deleting license');
    }
  }
};

const resetLicensePlate = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.licensePlateRelated.RESET_LICENSE_PLATES,
    });
  }
};

export default {
  getList,
  postLicensePlate,
  patchLicensePlate,
  deleteLicensePlate
}
