const requestTypes = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  DELETE: "DELETE",
  PATCH: "PATCH",
  POST_MULTIPART: "POST_MULTIPART",
  GET_FILE: "GET_FILE",
  POST_FILE: "POST_FILE",
  GET_FILE_ARRAY: "GET_FILE_ARRAY"
};

export {requestTypes};

export default requestTypes;
