import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getList = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.userRelated.list);

      dispatch({
        type: actionTypes.userRelated.GET_USER_LIST,
        response
      });
    }
    catch (error)
    {
      toastr.error('Error loading users');
    }
  }
};

const getMeta = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.userRelated.meta);

      dispatch({
        type: actionTypes.userRelated.GET_USER_META_SUCCESS,
        response
      });

      return response;
    }
    catch (error)
    {
      toastr.error('Error loading user settings');
    }
  }
};

const postUser = (data) => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.POST, apiRoutes.userRelated.post, data);

      dispatch({
        type: actionTypes.userRelated.POST_USER_SUCCESS,
        response
      });

      toastr.success("Gebruiker succesvol toegevoegd")
    }
    catch (error)
    {
      toastr.error('Error saving user');
    }
  }
};

const patchUser = (userId, data) => {
  return async dispatch => {
    try {
      const url = apiRoutes.userRelated.patch.replace('{userId}', userId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch({
        type: actionTypes.userRelated.PATCH_USER_SUCCESS,
        response
      });

      toastr.success("Gebruiker succesvol bijgewerkt")
    }
    catch (error)
    {
      toastr.error('Error saving user');
    }
  }
};

const saveMeta = (meta) => {
  return async dispatch => {
    try {
      dispatch({
        type: actionTypes.userRelated.SET_USER_META,
        meta
      });

      const url = apiRoutes.userRelated.meta;
      const response = await ApiService.performRequest(RequestType.PUT, url, meta);

      dispatch({
        type: actionTypes.userRelated.PUT_USER_META_SUCCESS,
        response
      });
    }
    catch (error)
    {
      toastr.error('Error saving user settings');
    }
  }
};

const deleteUser = (userId) => {
  return async dispatch => {
    try {
      const url = apiRoutes.userRelated.patch.replace('{userId}', userId);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch({
        type: actionTypes.userRelated.DELETE_USER_SUCCESS,
        response
      });

      toastr.success("Gebruiker verwijderd")
    }
    catch (error)
    {
      toastr.error('Error deleting user');
    }
  }
};

const resetUser = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.userRelated.RESET_USER,
    });
  }
};

export default {
  getList,
  resetUser,
  postUser,
  patchUser,
  deleteUser,
  getMeta,
  saveMeta
}
