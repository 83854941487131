import {actionTypes} from '../constants/actionTypes';

const emptyCustomer = {
  name: '',
  searchName: '',
  postalCode: '',
  addressLine1: '',
  city: '',
  kvkNumber: '',
  email: '',
  weighingSlipEmail: '',
  phoneNumber: ''
}

const initialState = {
  allCustomers: [],
  customers: [],
  emptyCustomer: {...emptyCustomer},
  customer: {},
  listTotal: 0,
  page: 1,
  pageSize: 10,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.customerRelated.GET_LIST:
      return {
        ...state,
        customers: action.response.list,
        listTotal: action.response.total
      };
    case actionTypes.customerRelated.RESET_CUSTOMER:
      return {
        ...state,
        emptyCustomer: {...emptyCustomer},
        customer: {}
      }
    case actionTypes.customerRelated.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.response
      }
    case actionTypes.customerRelated.SET_PAGINATION_PROPS:
      return {
        ...state,
        page: action.payload.page,
        pageSize: action.payload.sizePerPage,
      };
    case actionTypes.customerRelated.RESET_CUSTOMERS:
      return {
        ...state,
        customers: [],
        listTotal: 0,
      };
    case actionTypes.customerRelated.GET_ALL_CUSTOMERS:
      return {
        ...state,
        allCustomers: action.response
      }
    default:
      return state;
  }
};
