import React, {Fragment, useEffect, useState} from 'react';
import {Container, Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import customerActions from "../../../actions/customerActions";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import {Pencil, Trash} from "react-bootstrap-icons";
import {useHistory} from "react-router-dom";
import productActions from "../../../actions/productActions";
import ProductForm from "./form";

const Products = (props) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const history = useHistory();
  const {products} = props.product;

  useEffect(() => {
    props.productActions.getList();
  }, []);

  const formatCurrency = numberToFormat => {
    return new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR'
    }).format(isNaN(numberToFormat) ? 0 : numberToFormat);
  };

  const columns = [
    {
      dataField: 'id',
      text: 'id'
    },
    {
      dataField: 'code',
      text: 'Artikelcode',
      filter: textFilter()
    },
    {
      dataField: 'searchName',
      text: 'Zoeknaam',
      filter: textFilter()
    },
    {
      dataField: 'description',
      text: 'Omschrijving',
      filter: textFilter()
    },
    {
      dataField: 'ledger',
      text: 'Grootboek',
      filter: textFilter()
    },
    {
      dataField: 'pricePer',
      text: 'Prijs per',
      filter: textFilter()
    },
    {
      dataField: 'unit',
      text: 'Eenheid',
      filter: textFilter()
    },
    {
      dataField: 'price',
      text: 'Prijs excl. BTW',
      filter: textFilter(),
      formatter: (cell, row) => formatCurrency(row.price),
      style: {float: 'right'}
    },
    {
      dataField: 'btwPercentage',
      text: 'BTW',
      filter: textFilter()
    },
    {
      dataField: 'id',
      formatter: (cell, row) => {
        return <Button size="sm" variant="secondary" onClick={() => {setSelectedProduct(row); setShowModal(true);}}><Pencil /></Button>
      }
    },
    {
      dataField: 'id',
      formatter: (cell, row) => {
        return <Button size="sm" variant="danger" onClick={() => {
          if (window.confirm('Weet je het zeker?')) {
            props.productActions.deleteProduct(row.id).then(() => props.productActions.getList());
          }
        }}><Trash/></Button>
      }
    }
  ];

  const handleSubmit = (values) => {
    let newData = {...values};
    if(values.id) {
      delete newData.id;
      props.productActions.patchProduct(values.id, newData).then(() => {
        setShowModal(false);
        props.productActions.getList();
      });
    } else {
      props.productActions.postProduct(newData).then(() => {
        setShowModal(false);
        props.productActions.getList();
      });
    }
  };

  return (
    <Container fluid>
      <h2>Artikelen</h2>

      <Button
        variant="success"
        onClick={() => {
          setSelectedProduct(props.product.emptyProduct);
          setShowModal(true);
        }}
        size="sm"
      >
        Nieuw
      </Button> <br /><br />

      <BootstrapTable
        keyField='id'
        data={products}
        columns={columns}
        filter={filterFactory()}
        bootstrap4={true}
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {<ProductForm
            handleSubmit={handleSubmit}
            data={selectedProduct}
          />}
        </Modal.Body>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    product: state.product
  }
};

const mapDispatchToProps = dispatch => {
  return {
    productActions: bindActionCreators(productActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
