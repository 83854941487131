import {
  actionTypes
} from '../constants/actionTypes';

const emptyProject = {
  product: null,
  description: '',
  startTime: '',
  endTime: '',
  m3: '',
  tonnage: '',
  address: '',
  productPrice: 0,
  firstWeighting: 0,
  secondWeighting: 0,
  weighingSlip: '',
  carrier: '',
  licensePlate: '',
  paid: false,
  cash: false,
  unit: ''
};

const initialState = {
  emptyItem: {
    customer: null,
    title: '',
    postalCode: '',
    invoiceAddress: '',
    email: '',
    phoneNumber: '',
    workNumber: '',
    nameShip: '',
    nameContactPerson: '',
    nameDriver: '',
    description: '',
    agreeDriver: '',
    emailDriver: '',
    agreeCustomer: '',
    emailCustomer: '',
    cleanBobCat: true,
    cleanKnijper: true,
    cleanVeeg: true,
    drain: '',
    start: null,
    end: null,
    planningResource: {},
    projects: [],
    invoiced: false
  },
  emptyProject: {...emptyProject},
  items: [],
  licensePlates: []
}

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.itemRelated.GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.response
      };
    case actionTypes.itemRelated.POST_ITEM_SUCCESS:
      let postItems = [...state.items];
      postItems.push(action.response.item);
      return {
        ...state,
        items: postItems
      }
    case actionTypes.itemRelated.PATCH_ITEM_SUCCESS:
      let patchItems = [...state.items];
      const foundIndex = patchItems.findIndex(item => item.id === action.response.item.id);

      if(foundIndex > -1) {
        patchItems[foundIndex] = action.response.item;
      }

      return {
        ...state,
        items: patchItems
      }
    case actionTypes.itemRelated.DELETE_ITEM_SUCCESS:
      return {
        ...state,
        items: [...state.items].filter(item => item.id !== action.itemId)
      }
    case actionTypes.itemRelated.GET_LICENSE_PLATES_SUCCESS:
      return {
        ...state,
        licensePlates: action.response
      }
    default:
      return state;
  }
}
