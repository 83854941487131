const actionTypes = {
    resourceRelated: {
        GET_RESOURCES_SUCCESS: "GET_RESOURCES_SUCCESS",
        GET_RESOURCES_FAIL: "GET_RESOURCES_FAIL",
        DELETE_RESOURCE_SUCCESS: "DELETE_RESOURCE_SUCCESS",
        POST_RESOURCE_SUCCESS: "POST_RESOURCE_SUCCESS",
        POST_RESOURCE_FAIL: "POST_RESOURCE_FAIL",
        PATCH_RESOURCE_SUCCESS: "PATCH_RESOURCE_SUCCESS",
        PATCH_RESOURCE_FAIL: "PATCH_RESOURCE_FAIL",
        POST_MAINTENANCE_SUCCESS: "POST_MAINTENANCE_SUCCESS",
        POST_MAINTENANCE_FAIL: "POST_MAINTENANCE_FAIL",
        PATCH_MAINTENANCE_SUCCESS: "PATCH_MAINTENANCE_SUCCESS",
        POST_RESOURCE_FILE_SUCCESS: "POST_RESOURCE_FILE_SUCCESS",
        POST_RESOURCE_FILE_FAIL: "POST_RESOURCE_FILE_FAIL",
        DELETE_RESOURCE_FILE_SUCCESS: "DELETE_RESOURCE_FILE_SUCCESS",
        POST_RESOURCE_CATEGORY_SUCCESS: "POST_RESOURCE_CATEGORY_SUCCESS",
        POST_RESOURCE_CATEGORY_FAIL: "POST_RESOURCE_CATEGORY_FAIL",
        PATCH_RESOURCE_CATEGORY_SUCCESS: "PATCH_RESOURCE_CATEGORY_SUCCESS",
        PATCH_RESOURCE_CATEGORY_FAIL: "PATCH_RESOURCE_CATEGORY_FAIL",
        GET_RESOURCE_CATEGORIES_SUCCESS: "GET_RESOURCE_CATEGORIES_SUCCESS",
        GET_RESOURCE_CATEGORIES_FAIL: "GET_RESOURCE_CATEGORIES_FAIL",
        DELETE_RESOURCE_CATEGORY_SUCCESS: "DELETE_RESOURCE_CATEGORY_SUCCESS",
        SET_RESOURCES: "SET_RESOURCES",
        SET_CATEGORIES: "SET_CATEGORIES"
    },
    itemRelated: {
        GET_ITEMS_SUCCESS: "GET_ITEMS_SUCCESS",
        GET_ITEMS_FAIL: "GET_ITEMS_FAIL",
        POST_ITEM_SUCCESS: "POST_ITEM_SUCCESS",
        POST_ITEM_FAIL: "POST_ITEM_FAIL",
        PATCH_ITEM_SUCCESS: "PATCH_ITEM_SUCCESS",
        PATCH_ITEM_FAIL: "PATCH_ITEM_FAIL",
        DELETE_ITEM_SUCCESS: "DELETE_ITEM_SUCCESS",
        GET_LICENSE_PLATES_SUCCESS: "GET_LICENSE_PLATES_SUCCESS"
    },
    authRelated: {
        LOGIN: "LOGIN",
        LOGIN_SUCCESS: "LOGIN_SUCCESS",
        LOGIN_FAIL: "LOGIN_FAIL",
        LOGOUT: "LOGOUT",
        GET_INFO_SUCCESS: "GET_INFO_SUCCESS"
    },
    customerRelated: {
        GET_LIST: "GET_LIST",
        RESET_CUSTOMER: "RESET_CUSTOMER",
        RESET_CUSTOMERS: "RESET_CUSTOMERS",
        POST_CUSTOMER_SUCCESS: "POST_CUSTOMER_SUCCESS",
        PATCH_CUSTOMER_SUCCESS: "PATCH_CUSTOMER_SUCCESS",
        DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
        GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
        SET_PAGINATION_PROPS: "SET_PAGINATION_PROPS",
        GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS"
    },
    userRelated: {
        GET_USER_LIST: "GET_USER_LIST",
        RESET_USER: "RESET_USER",
        POST_USER_SUCCESS: "POST_USER_SUCCESS",
        PATCH_USER_SUCCESS: "PATCH_USER_SUCCESS",
        DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
        GET_USER_META_SUCCESS: "GET_USER_META_SUCCESS",
        PUT_USER_META_SUCCESS: "PUT_USER_META_SUCCESS",
        SET_USER_META: "SET_USER_META"
    },
    productRelated: {
        GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
        RESET_PRODUCT: "RESET_PRODUCT",
        POST_PRODUCT_SUCCESS: "POST_PRODUCT_SUCCESS",
        PATCH_PRODUCT_SUCCESS: "PATCH_PRODUCT_SUCCESS",
        DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
        GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
        GET_PRODUCT_PRICE: "GET_PRODUCT_PRICE"
    },
    licensePlateRelated: {
        GET_LICENSE_PLATES_LIST: "GET_LICENSE_PLATES_LIST",
        RESET_LICENSE_PLATES: "RESET_LICENSE_PLATES",
        POST_LICENSE_PLATES_SUCCESS: "POST_LICENSE_PLATES_SUCCESS",
        PATCH_LICENSE_PLATES_SUCCESS: "PATCH_LICENSE_PLATES_SUCCESS",
        DELETE_LICENSE_PLATES_SUCCESS: "DELETE_LICENSE_PLATES_SUCCESS",

    },
    noticeRelated: {
        GET_NOTICE_SUCCESS: "GET_NOTICE_SUCCESS",
        PATCH_NOTICE_SUCCESS: "PATCH_NOTICE_SUCCESS",
    },
};

export {actionTypes};

export default actionTypes;
