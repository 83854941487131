import React, {useEffect} from 'react';
import {Row, Col, Container} from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link, useHistory} from "react-router-dom";

import Middel1 from '../../assets/img/Middel 1.png';
import Middel2 from '../../assets/img/Middel 2.png';
import Middel3 from '../../assets/img/Middel 3.png';
import Middel4 from '../../assets/img/Middel 4.png';
import Middel5 from '../../assets/img/Middel 5.png';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import authenticationActions from "../../actions/authenticationActions";

const Home = (props) => {

  useEffect(() => {
    props.authenticationActions.info();
  }, []);

  const history = useHistory();
  const {currentUser} = props.authentication;

  return (
    <Row>
      <Col>
        <Container style={{marginTop: '20vh'}}>
          <Row>
            <Col>
              <h2 style={{fontFamily: 'arial, serif', fontWeight: 'bold'}}>Welkom {currentUser.firstName}</h2>
            </Col>
          </Row>
          <Row className="home-cols">
            <Col>
              <a href='#' onClick={() => history.push('/')}>
                <img src={Middel1} /> <br />
                Home
              </a>
            </Col>
            <Col>
              <a href='#' onClick={() => history.push('/admin/customers')}>
                <img src={Middel2} /> <br />
                Klanten
              </a>
            </Col>
            <Col>
              <a href='#' onClick={() => history.push('/admin/resources')}>
                <img src={Middel3} /> <br />
                Middelen
              </a>
            </Col>
            <Col>
              <a href='#' onClick={() => history.push('/admin/products')}>
                <img src={Middel4} /> <br />
                Artikelen
              </a>
            </Col>
            <Col>
              <a href='#' onClick={() => history.push('/admin/users')}>
                <img src={Middel5} /> <br />
                Gebruikers
              </a>
            </Col>
            <Col>
              <a href='#' onClick={() => history.push('/admin/notices')}>
                <img src={Middel5} /> <br />
                Mededelingen
              </a>
            </Col>
            <Col>
              <a href='#' onClick={() => history.push('/admin/license-plates')}>
                <img src={Middel5} /> <br />
                Kentekens
              </a>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication
  }
};

const mapDispatchToProps = dispatch => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
