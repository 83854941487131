import axios from 'axios';
import RequestType from '../constants/requestType';
import {apiRoutes} from '../constants/apiRoutes';
import store from '../store';
import AuthenticationService from "./authenticationService";


export default class ApiService {

    static ROOT_URL = apiRoutes.rootUrl;

    static async performRequest(type, url, data = null) {
        let token = AuthenticationService.getToken();
        let response;

        try{
            switch(type) {
                case RequestType.GET:
                    let req = await axios.get(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
                    response =  req;
                    return response.data;
                    break;
                case RequestType.GET_FILE_ARRAY:
                    response = await this.getFileRequest(url);
                    let fileName = '';
                    try {
                        fileName = response.headers['content-disposition'].split('filename=')[1];
                    } catch (e) {
                        fileName = '';
                    }

                    return {
                        blob: response.data,
                        fileName: fileName.replace('"','').replace('"','')
                    };
                case RequestType.GET_FILE:
                    response = await this.getFileRequest(url);
                    break;
                case RequestType.POST_FILE:
                    response = await this.postFileRequest(url, data);
                    break;
                case RequestType.PUT:
                    response = await this.putRequest(url, data);
                    break;
                case RequestType.DELETE:
                    response = await this.deleteRequest(url);
                    break;
                case RequestType.POST:
                    response = await this.postRequest(url, data);
                    break;
                case RequestType.PATCH:
                    response  = await this.patchRequest(url, data);
                    break;
                case RequestType.POST_MULTIPART:
                    response = await this.postMultipartRequest(url, data);
                    break;
                default:
                    return null;
            }

            return response.data;
        } catch(e) {
            if(e.response.status === 401) {
                if (AuthenticationService.getToken()) {
                    //store.dispatch(loginActions.sessionHasExpired());
                } else {
                    //store.dispatch(loginActions.logout());
                }
            } else {
                throw e;
            }
        }


    }

    static getFileRequest = async (url) => {
        let token = AuthenticationService.getToken();
        return await axios.get(ApiService.ROOT_URL + url, { responseType:"blob",  headers: {'Authorization' : `Bearer ${token}`}});
    }

    static postFileRequest = async (url, data) => {
        let token = AuthenticationService.getToken();
        return await axios.post(ApiService.ROOT_URL + url, data, { responseType:"blob",  headers: {'Authorization' : `Bearer ${token}`}});
    }

    static putRequest = async (url, data) => {
        let token = AuthenticationService.getToken();
        return await axios.put(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static postMultipartRequest = async (url, data) => {
        let token = AuthenticationService.getToken();

        // Convert data to form data
        let formData = new FormData();
        Object.keys(data).forEach(function(item) {
            formData.append(item, data[item]);
        });

        return axios.post(ApiService.ROOT_URL + url, formData, {headers: {
            'Authorization' : `Bearer ${token}`,
            'Content-Type' : 'multipart/form-data'
        }});
    };

    static deleteRequest = async (url) => {
      let token = AuthenticationService.getToken();
        return await axios.delete(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static postRequest = async (url, data) => {
        let token = AuthenticationService.getToken();
        if (data instanceof File) {
            let formData = new FormData();
            formData.append('file', data);
            return await axios.post(ApiService.ROOT_URL + url, formData, {headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type' : 'multipart/form-data'
            }});
        }
        else {
            return await axios.post(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}});
        }
    };

    static patchRequest = async (url, data) => {
        let token = AuthenticationService.getToken();
        return await axios.patch(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}})
    }
}
