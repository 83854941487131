import React, {Fragment, useEffect, useState} from 'react';
import {Container, Button, Tabs, Tab, Table, Form} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";

const PriceForm = (props) => {
  const emptyPrice = {
    product: null,
    price: 0,
    discount: 0
  };

  const {prices} = props;
  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(props.prices);
  }, [props.prices]);

  const getFormattedProducts = () => {
    return props.products.map(product => (
      {
        id: product.id,
        name: product.searchName + ' - ' + product.description,
        price: product.price,
        unit: product.unit
      }
    ));
  };

  const handleChange = (name, value, index) => {
    let newValues = [...values];
    newValues[index][name] = value;

    setValues(newValues);
  };

  const getSelectedProduct = (index) => {
    const products = getFormattedProducts();
    const selectedProduct = products.find(product => product.id === values[index]['product']);

    if (selectedProduct !== undefined) {
      return [selectedProduct];
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <h3>Prijs afspraken</h3>

      <Table striped bordered>
        <thead>
        <tr>
          <th>Product</th>
          <th>Prijs p/s</th>
          <th>Korting p/s</th>
        </tr>
        </thead>
        <tbody>
        {values.map((data, key) =>
          <tr>
            <td>
              <Typeahead
                className="product-select"
                name={'testasdf'}
                id="basic-typeahead-single"
                labelKey="name"
                options={getFormattedProducts()}
                placeholder="Kies een product"
                onChange={(data) => {
                  let product = data[0];
                  handleChange('product', product === undefined ? null : product.id, key);
                }}
                selected={getSelectedProduct(key)}
              />
            </td>
            <td>
              <Form.Control
                name="price"
                type={'number'}
                value={data.price}
                onChange={(e) => handleChange(e.target.name, e.target.value, key)}
              />
            </td>
            <td>
              <Form.Control
                name="discount"
                type={'number'}
                value={data.discount}
                onChange={(e) => handleChange(e.target.name, e.target.value, key)}
              />
            </td>
          </tr>
        )}
        </tbody>
      </Table>

      <Button variant="success" onClick={() => {
        let newValues = [...values];
        newValues.push({...emptyPrice});
        setValues(newValues);
      }}>Voeg prijs toe</Button>

      <Button className="float-right" onClick={() => props.handleSubmit(values)}>Opslaan</Button>
    </Fragment>
  )
}

export default PriceForm;
