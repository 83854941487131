import {actionTypes} from '../constants/actionTypes';
import AuthenticationService from "../services/authenticationService";

const initialState = {
  isLoggedIn: !AuthenticationService.isTokenExpired(),
  loginMessage: '',
  token: '',
  currentUser: {
    firstName: '',
    lastName: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.authRelated.LOGIN:
      return {
        ...state,
        isLoggedIn: false,
        loginMessage: ''
      };
    case actionTypes.authRelated.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: action.token,
      };
    case actionTypes.authRelated.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        loginMessage: 'Incorrecte gebruikersnaam/wachtwoord'
      };
    case actionTypes.authRelated.GET_INFO_SUCCESS:
      return {
        ...state,
        currentUser: action.response
      }
    case actionTypes.authRelated.LOGOUT:
      AuthenticationService.logout();
      return {
        ...state,
        isLoggedIn: false
      }
    default:
      return state;
  }
};
