import React, {Fragment, useEffect, useState} from 'react';
import {Container, Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import customerActions from "../../../actions/customerActions";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import PlanningForm from "../../Planning/form";
import CustomerForm from "./form";
import {Pencil, Trash} from "react-bootstrap-icons";
import {useHistory} from "react-router-dom";
import PaginationFactory, {} from 'react-bootstrap-table2-paginator';
import paginationHelper from "../../../includes/paginationHelper";

const Customers = (props) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState('');

  const history = useHistory();
  const {customers} = props.customer;

  useEffect(() => {
    props.customerActions.getList();
  }, []);

  const columns = [
    {
      dataField: 'id',
      text: 'id'
    },
    {
      dataField: 'name',
      text: 'Naam',
      filter: textFilter()
    },
    {
      dataField: 'postalCode',
      text: 'Postcode',
      filter: textFilter()
    },
    {
      dataField: 'addressLine1',
      text: 'Adres',
      filter: textFilter()
    },
    {
      dataField: 'city',
      text: 'Woonplaats',
      filter: textFilter()
    },
    {
      dataField: 'kvkNumber',
      text: 'KVK Nummer',
      filter: textFilter()
    },
    {
      dataField: 'email',
      text: 'Email',
      filter: textFilter()
    },
    {
      dataField: 'testadf',
      formatter: (cell, row) => {
        return <Button size="sm" variant="secondary" onClick={() => history.push('/admin/customer/' + row.id)}><Pencil /></Button>
      }
    },
    {
      dataField: 'tesadsfasf',
      formatter: (cell, row) => {
        return <Button size="sm" variant="danger" onClick={() => {
          if (window.confirm('Weet je het zeker?')) {
            props.customerActions.deleteCustomer(row.id).then(() => props.customerActions.getList());
          }
        }}><Trash/></Button>
      }
    }
  ];

  const handleSubmit = (values) => {
    let newData = {...values};
    if(values.id) {
      delete newData.id;
      props.customerActions.patchCustomer(values.id, newData).then(() => {
        setShowModal(false);
        props.customerActions.getList();
      });
    } else {
      props.customerActions.postCustomer(newData).then(() => {
        setShowModal(false);
        props.customerActions.getList();
      });
    }
  };

  const pagination = (page, sizePerPage) => {
    const {
      getList,
      setPaginationProps,
      reset
    } = props.customerActions;

    paginationHelper.changePageNumberOrSize(page, sizePerPage, setLoading, queryString, '', reset, setPaginationProps, getList);
  };

  return (
    <Container fluid>
      <h2>Klanten</h2>

      <Button
        variant="success"
        onClick={() => {
          setSelectedCustomer(props.customer.emptyCustomer);
          setShowModal(true);
        }}
        size="sm"
      >
        Nieuw
      </Button> <br /><br />

      <BootstrapTable
        keyField='id'
        data={customers}
        columns={columns}
        filter={filterFactory()}
        remote={{
          pagination: true,
          filter: true
        }}
        onTableChange={(type, data) => {
          if (type !== 'filter') { return; }
          let query = {};
          /**
           * caseSensitive: false
           comparator: "LIKE"
           filterType: "TEXT"
           filterVal: "test"
           */
          Object.keys(data.filters).forEach(name => {
            query[name] = data.filters[name]['filterVal'];
          });
          const queryParams = new URLSearchParams(query);
          setQueryString(queryParams.toString());

          props.customerActions.setPaginationProps(1, props.customer.pageSize);
          props.customerActions.getList(queryParams.toString());
        }}
        pagination={PaginationFactory({
          totalSize: props.customer.listTotal,
          sizePerPage: props.customer.pageSize,
          onPageChange: (page, sizePerPage) => {
            pagination(page, sizePerPage);
          },
          onSizePerPageChange: (sizePerPage, page) => {
            pagination(page, sizePerPage);
          }
        })}
        bootstrap4={true}
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Klant
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerForm
            handleSubmit={handleSubmit}
            data={selectedCustomer}
          />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer
  }
};

const mapDispatchToProps = dispatch => {
  return {
    customerActions: bindActionCreators(customerActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
