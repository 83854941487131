import React from 'react';
import {Row, Col, Button, Form, Tabs, Tab, Table} from "react-bootstrap";
import {Formik} from "formik";
import DatePicker from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LicensePlateForm = (props) => {
  return (
    <Formik
      initialValues={props.data}
      enableReinitialize={true}
      validationSchema={false}
      onSubmit={(values, {setErrors}) => {
        props.handleSubmit(values);
      }}
    >
      {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue
        }) => {

        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group style={{display: 'none'}} as={Row}>
                  <Form.Label column={true} lg={3}></Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'id'}
                      readOnly={false}
                      type={'text'}
                      name={'id'}
                      value={values.id}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Kenteken</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'licensePlate'}
                      readOnly={false}
                      placeholder='Kenteken'
                      type={'text'}
                      name={'licensePlate'}
                      value={values.licensePlate}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Standaard Gewicht</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'defaultWeighing'}
                      readOnly={false}
                      placeholder='Standaard Gewicht'
                      type={'number'}
                      name={'defaultWeighing'}
                      value={values.defaultWeighing}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="primary"
              type="submit"
              className="float-right"
            >
              Opslaan
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LicensePlateForm;
