import React from 'react';
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {Formik} from "formik";

const CategoryForm = (props) => {

  const { showModal, setShowModal, selectedCategory } = props;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Categorie
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={selectedCategory}
          enableReinitialize={true}
          validationSchema={false}
          onSubmit={(values, {setErrors}) => {
            props.handleSubmit(values);
          }}
        >
          {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue
            }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row}>
                  <Form.Label column={true} lg={3}>Naam</Form.Label>
                  <Col lg={9}>
                    <Form.Control
                      id={'name'}
                      readOnly={false}
                      placeholder='Naam'
                      type={'text'}
                      name={'name'}
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Button type="submit" variant="primary">Opslaan</Button>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CategoryForm;