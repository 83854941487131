import { createStore, compose, applyMiddleware } from 'redux';
import { combineReducers } from 'redux'
import Reducers from './reducers';
import thunk from 'redux-thunk';
import {reducer as toastrReducer} from 'react-redux-toastr'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(combineReducers({
  ...Reducers,
  toastr: toastrReducer
}), composeEnhancer(applyMiddleware(thunk)));

export default store;
