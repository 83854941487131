import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'


const getNotice = () => {
  return async dispatch => {
    try {
      const response = await ApiService.performRequest(RequestType.GET, apiRoutes.noticeRelated.getNotice);
      dispatch({
        type: actionTypes.noticeRelated.GET_NOTICE_SUCCESS,
        response
      });
      return response;
    }
    catch (error)
    {
      toastr.error('Error loading Notice');
    }
  }
};

const patchNotice = (data) => {
  console.log(data);
  return async dispatch => {
    try {
      const url =  apiRoutes.noticeRelated.patch;
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch({
        type: actionTypes.noticeRelated.PATCH_NOTICE_SUCCESS,
        response
      });

      toastr.success("Mededeling succesvol bijgewerkt")
    }
    catch (error)
    {
      toastr.error('Error saving user');
    }
  }
};

export default {
  getNotice,
  patchNotice
}
