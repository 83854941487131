import React from "react";
import JWT from 'jwt-decode';
import * as moment from 'moment';
import {toastr} from 'react-redux-toastr';
import store from '../store';
import authenticationActions from "../actions/authenticationActions";
import {Redirect} from "react-router";

export default class AuthenticationService {
  static logout() {
    localStorage.removeItem('token');
    store.dispatch(authenticationActions.logout());
  }

  static isLoggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  static isTokenExpired(token) {
    try {
      const decoded = JWT(token);
      if (decoded.exp < Date.now() / 1000)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    catch (err) {
      return false;
    }
  }

  static getToken() {
    const state = store.getState();
    return localStorage.getItem('token') ?? state.authentication.token;
  }

  static setToken(token) {
    localStorage.setItem('token', token)
  }

  static getDecodedToken() {
    if (this.getToken() !== null) {
      return JWT(this.getToken());
    }
    return {};
  }
};