import resource from './resourceReducer';
import item from './itemReducer';
import authentication from './authenticationReducer';
import customer from './customerReducer';
import user from './userReducer';
import product from './productReducer';
import licensePlate from './licensePlateReducer';

export default {
  resource,
  item,
  authentication,
  customer,
  user,
  product,
  licensePlate
};
